import React, { useEffect, useMemo, useState } from "react";
import { 
    Button, 
    Modal,
    Space,
    Select,
    Typography,
    Input,
    Alert,
    Radio
} from 'antd';

import { checkEmptyObjectKeys } from "../../_helpers";
import { editSantri } from "../../_services";

const { Text } = Typography;

export const ComponentModalEdit = ({dataRow, isModalEditOpen, setModalEditOpen, setIsSuccessHitAPI}) => {
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        lembaga: "",
        nis: "",
        kelas: "",
        nama: "",
        jenis_kelamin: "",
        email: "",
        nomor_hp: "",
        nama_orang_tua_wali: "",
        nomor_hp_orang_tua_wali: "",
        alamat: "",
    });

    useEffect(() => {
        setFormData({
            lembaga: dataRow?.lembaga,
            nis: dataRow?.nis,
            kelas: dataRow?.kelas,
            nama: dataRow?.nama,
            jenis_kelamin: dataRow?.jenis_kelamin,
            email: dataRow?.email,
            nomor_hp: dataRow?.nomor_hp,
            nama_orang_tua_wali: dataRow?.nama_orang_tua_wali,
            nomor_hp_orang_tua_wali: dataRow?.nomor_hp_orang_tua_wali,
            alamat: dataRow?.alamat,
        });
    }, [isModalEditOpen]);
    // console.table(checkEmptyObjectKeys(formData));

    return (
        <Modal 
            title="Edit Santri" 
            open={isModalEditOpen} 
            onCancel={() => {
                setModalEditOpen(!isModalEditOpen);
            }}
            footer={[
                <Button 
                    key="cancel"
                    type="ghost" 
                    size="large"
                    onClick={() => {
                        setModalEditOpen(!isModalEditOpen);
                    }}
                >
                    Batal
                </Button>,
                <Button 
                    key="save"
                    type="ghost"
                    size="large"
                    className="button-custom-success" 
                    loading={isLoading}
                    disabled={checkEmptyObjectKeys(formData)}
                    onClick={() => {
                        editSantri(formData, setIsSuccessHitAPI, setModalEditOpen, setIsLoading);
                    }}
                >
                    <Text style={{ color: "white" }}>Simpan</Text>
                </Button>
            ]}
        >
            <Space direction="vertical" style={{ display: "flex" }}>
                <Text style={{ fontWeight: "bold", textTransform: "capitalize" }}>{dataRow?.lembaga}</Text>

                {/* NIS */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>NIS</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            disabled={formData?.nis === null || (formData?.nis !== null && formData.nis?.length <= 8) ? false : true}
                            size="large"
                            value={formData.nis}
                            onChange={(e) => {
                                setFormData({...formData, nis: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* NIS */}

                {/* KELAS */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Kelas</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.kelas}
                            onChange={(e) => {
                                setFormData({...formData, kelas: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* KELAS */}

                {/* NAMA */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Nama</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.nama}
                            onChange={(e) => {
                                setFormData({...formData, nama: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* Nama */}

                {/* GENDER */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Jenis Kelamin</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Radio.Group 
                            value={formData.jenis_kelamin}
                            onChange={(e) => {
                                console.log(e.target.value);
                                setFormData({...formData, jenis_kelamin: e.target.value});
                            }} 
                        >
                            <Radio value="L">Laki-Laki</Radio>
                            <Radio value="P">Perempuan</Radio>
                        </Radio.Group>
                    </div>
                </div>
                {/* GENDER */}

                {/* EMAIL */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Email</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.email}
                            onChange={(e) => {
                                setFormData({...formData, email: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* EMAIL */}

                {/* PHONE NUMBER */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Nomor HP</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.nomor_hp}
                            onChange={(e) => {
                                setFormData({...formData, nomor_hp: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* PHONE NUMBER */}

                {/* PARENT NAME */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Nama Orang Tua / Wali</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.nama_orang_tua_wali}
                            onChange={(e) => {
                                setFormData({...formData, nama_orang_tua_wali: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* PARENT NAME */}

                {/* PARENT CONTACT */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Nomor HP Orang Tua / Wali</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.nomor_hp_orang_tua_wali}
                            onChange={(e) => {
                                setFormData({...formData, nomor_hp_orang_tua_wali: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* PARENT CONTACT */}

                {/* ADDRESS */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", width: "30%", alignItems: "center", justifyContent: "flex-end" }}>
                        <Text>Alamat</Text>
                    </div>
                    <div style={{ display: "flex", width: "65%" }}>
                        <Input
                            size="large"
                            value={formData.alamat}
                            onChange={(e) => {
                                setFormData({...formData, alamat: e.target.value});
                            }}
                        />
                    </div>
                </div>
                {/* ADDRESS */}
                
                {
                    checkEmptyObjectKeys(formData) &&
                    <Alert 
                        message="Semua kolom wajib diisi"
                        type="warning"
                        showIcon
                        closable
                    />
                }
            </Space>
        </Modal>
    );
};