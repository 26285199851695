import { read, utils, writeFile } from 'xlsx';

export const exportToExcel = (headings, initialData, sheetName, fileName, sheetTitle, sheetNotes) => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    // utils.sheet_add_aoa(ws, headings);
    // utils.sheet_add_json(ws, initialData, { origin: 'A2', skipHeader: true });
    utils.sheet_add_aoa(ws, [[ `${sheetTitle}` ]], { origin: "A1" });
    utils.sheet_add_aoa(ws, [[ `Penting: Jangan mengubah judul kolom pada excel ini.  Isikan semua kolom tanpa terkecuali. ${sheetNotes}` ]], { origin: "A2" });
    utils.sheet_add_aoa(ws, headings, { origin: "A4" });
    utils.sheet_add_json(ws, initialData, { origin: 'A5', skipHeader: true });
    utils.book_append_sheet(wb, ws, sheetName);
    writeFile(wb, fileName);
};

export const importFromExcel = (event, setImportedRowsData) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
            // const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { range: 3 });
            setImportedRowsData(rows);
        }
    }
    reader.readAsArrayBuffer(event.file.originFileObj);
};