export const removeEmptyObjectKeys = (object) => {
    for (let keys in object) {
        if (object[keys] === "" || object[keys] === null || object[keys] === undefined) {
            delete(object[keys]);
        }
    }
    return object;
};

export const checkEmptyObjectKeys = (object) => {
    for (let keys in object) {
        if (object[keys] === "" || object[keys] === null || object[keys] === undefined) {
            return true;
        }
    }
    return false;
};