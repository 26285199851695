import React, { useMemo } from "react";
import { Select } from "antd";

const { Option } = Select;

export const UnitJenisSelection = ({showSearch, disable, formData, setFormData}) => {
    const { unit_jenis, unit_nama, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const allUnitJenis = JSON.parse(localStorage.getItem("unitJenis"));

    const unitJenisOptions = useMemo(() => {
        // return allUnitJenis;
        if (formData.userJenisID !== "5") {
            return allUnitJenis?.filter((data) => ![1, 2].includes(data.id));
        } 
        else {
            return allUnitJenis;
        }
    }, [formData.userJenisID]);
    // console.log(unitJenisOptions);

    const handleSelectedUnitJenis = (event) => {
        const selectedName = event.split("_")[0];
        const selectedID = event.split("_")[1];
        setFormData({
            ...formData,
            selectedInstitutionName: undefined,
            institutionName: undefined,
            institutionID: "",
            institutionPhoneNumber: "",
            institutionAddress: "",
            personalName: "", 
            studentIdNumber: "",
            studentName: undefined,
            studentUserID: "",
            employeeIdNumber: "",
            userName: "",
            userEmail: "",
            userPassword: "",
            unitJenisName: selectedName, 
            unitJenisID: selectedID,
            accountType: undefined,
        });
    };

    return (
        <Select
            size="large"
            placeholder="Pilih Unit Jenis"
            className="registration-page-selection"
            showSearch={showSearch}
            disabled={disable}
            optionFilterProp="children"
            value={formData?.unitJenisName}
            onChange={(e) => {
                handleSelectedUnitJenis(e);
            }}
            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {
                unitJenisOptions?.map((items, index) => 
                    <Option key={index} value={`${items.unitJenisNama}_${items.id}`}>{items.unitJenisNama}</Option>
                )
            }
        </Select>
    );
};