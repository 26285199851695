import axios from "axios";
import { removeEmptyObjectKeys, openNotification } from "../_helpers";

export const promiseAllPeriode = async (setDataPeriode, setDataPeriodeJenis) => {
    await Promise.all(
        [
            getPeriodeData(setDataPeriode),
            getPeriodeJenis(setDataPeriodeJenis),
        ]
    );
};

export const getPeriodeData = async () => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/finance/periode/find`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // setDataPeriode(response.data.data);
        localStorage.setItem("periode", JSON.stringify(response.data.data));
        // console.log(response.data.data);
    } catch (error) {
        // console.log(error.response);
    }
};

export const getPeriodeJenis = async () => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/finance/periode_jenis/find`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // setDataPeriodeJenis(response.data.data);
        localStorage.setItem("periodeJenis", JSON.stringify(response.data.data));
        // console.log(response.data.data);
    } catch (error) {
        // console.log(error.response);
    }
};

export const addPeriode = async (payload, handleCloseModal, setTableData, setIsLoading, setIsSuccessHitAPI) => {
    setIsLoading(true);
    setIsSuccessHitAPI(false);
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    const finalPayload = removeEmptyObjectKeys(payload);
    delete finalPayload["periodeJenisSemesterName"];
    delete finalPayload["periodeJenisSemesterID"];

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/finance/periode/insert`, finalPayload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        await getPeriodeData();
        const periode = JSON.parse(localStorage.getItem("periode"));
        setIsLoading(false);
        setIsSuccessHitAPI(true);

        // setTableData(periode);
        handleCloseModal();
        openNotification("success", "Sukses", "Data Periode berhasil ditambah!");
        // console.log(response.data);
    } catch (error) {
        setIsLoading(false);
        setIsSuccessHitAPI(false);
        openNotification("error", "Gagal", "Terjadi kesalahan saat menambahkan data periode, coba lagi!");
        // console.log(error.response);
    }
    // console.log(finalPayload);
};