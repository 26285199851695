import React from "react";

import "./_splashScreen.css";

export const SplashScreen = () => {
    return (
        <div className="splash-screen-container">
            SPLASHSCREEN
        </div>
    );
};