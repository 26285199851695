import React from "react";
import { checkEmptyObjectKeys } from "../../../_helpers";

export const rules = (formData, setAlertType, setAlertMessage, setAlertDescription, setStepButtonDisabled, currentStep) => {
    if (currentStep === 0) {
        switch (true) {
            case formData.userJenisID === "5" && formData.groupID === "1" && (formData.unitJenisID === "1" || formData.unitJenisID === "2") && formData.accountType === "createNew":
                setStepButtonDisabled(true);
                setAlertType("error");
                setAlertMessage(`Pendaftaran ${formData.unitJenisName} Baru`);
                setAlertDescription(`Anda tidak bisa melakukan Pendaftaran "${formData.unitJenisName}" Dan Akun "${formData.groupName}" Baru!`);
                break;
            case (formData.userJenisID === "5" && formData.groupID === "1" && formData.unitJenisID === "1" && formData.accountType === "addAccount") || (formData.userJenisID === "5" && formData.groupID !== undefined && formData.unitJenisID === "1"):
                setStepButtonDisabled(true);
                setAlertType("error");
                setAlertMessage(`Penambahan Akun ${formData.groupName}`);
                setAlertDescription(`Anda tidak bisa melakukan Penambahan Akun "${formData.groupName} ${formData.unitJenisName}"!`);
                break;
            case formData.userJenisID === "5" && formData.groupID === "1" && (formData.unitJenisID === "3" || formData.unitJenisID === "4") && formData.accountType === "createNew":
                setStepButtonDisabled(false);
                setAlertType("warning");
                setAlertMessage(`Pendaftaran ${formData.unitJenisName} Baru`);
                setAlertDescription(`Anda akan melakukan Pendaftaran "${formData.unitJenisName}" Dan Akun "${formData.groupName}" Baru.  Silahkan menuju ke Step selanjutnya!`);
                break;
            case formData.unitJenisName !== undefined && formData.accountType === "addAccount":
                setStepButtonDisabled(false);
                setAlertType("warning");
                setAlertMessage(`Penambahan Akun ${formData.groupName}`);
                setAlertDescription(`Anda akan menambahkan Akun "${formData.groupName}" ${formData.unitJenisName} anda.  Silahkan menuju ke Step selanjutnya!`);
                break;
            case Number(formData.groupID) > 1 && formData.unitJenisID !== undefined && formData.accountType === undefined:
                setStepButtonDisabled(false);
                setAlertType("warning");
                setAlertMessage(`Pendaftaran Akun ${formData.groupName}`);
                setAlertDescription(`Anda akan melakukan Pendaftaran Akun "${formData.groupName}"  ${formData.unitJenisName} anda.  Silahkan menuju ke Step selanjutnya!`);
                break;
            default:
                setStepButtonDisabled(true);
                setAlertType("warning");
                setAlertMessage("Pendaftaran");
                setAlertDescription(`Anda akan melakukan serangkaian proses pendaftaran. Wajib untuk menyelesaikan sampai step terakhir, jika tidak maka pendaftaran yang anda lakukan tidak akan tersimpan oleh system.  Silahkan ikuti step berikut!`);
                break;
        }
    }
    else if (currentStep === 1) {
        switch (true) {
            case (formData.userJenisID !== undefined && formData.groupID !== undefined && formData.unitJenisID === "3" && formData.accountType === "addAccount") || (formData.userJenisID !== undefined && formData.groupID !== undefined && formData.unitJenisID === "3"):
                setAlertType("warning");
                setAlertMessage(`Penambahan Akun ${formData.groupName}`);
                setAlertDescription(`Silahkan lanjut ke-Step terakhir!`);
                break;
            default:
                setAlertType("warning");
                setAlertMessage("Pendaftaran");
                setAlertDescription(`Anda akan melakukan serangkaian proses pendaftaran. Wajib untuk menyelesaikan sampai step terakhir, jika tidak maka pendaftaran yang anda lakukan tidak akan tersimpan oleh system.  Silahkan ikuti step berikut!`);
                break;
        }

        if (formData.institutionName === undefined) return setStepButtonDisabled(true);
        setStepButtonDisabled(false);
    }
    else {
        switch (true) {
            case formData.userJenisID === "5" && formData.groupID === "1" && (formData.unitJenisID === "3" || formData.unitJenisID === "4") && formData.accountType === "createNew":
                setAlertType("warning");
                setAlertMessage(`Pendaftaran ${formData.unitJenisName} Baru`);
                setAlertDescription(`Silahkan lengkapi form pendaftaran Akun "${formData.userJenisName}" berikut, pastikan semua form dengan tanda ( * ) terisi, kemudian silhkan klik "Simpan" untuk menyelesaikan Pendaftaran!`);
                break;
            case (formData.userJenisID === "5" && formData.groupID === "1" && (formData.unitJenisID === "1" || formData.unitJenisID === "2" || formData.unitJenisID === "3" || formData.unitJenisID === "4") && formData.accountType === "addAccount") || (formData.userJenisID === "5" && (formData.groupID === "2" || formData.groupID === "3" || formData.groupID === "4") && (formData.unitJenisID === "1" || formData.unitJenisID === "2" || formData.unitJenisID === "3" || formData.unitJenisID === "4")) || (formData.userJenisID !== "5" && formData.groupID !== undefined && (formData.unitJenisID === "3" || formData.unitJenisID === "4")):
                setAlertType("warning");
                setAlertMessage(`Penambahan Akun ${formData.groupName}`);
                setAlertDescription(`Silahkan lengkapi form pendaftaran Akun "${formData.userJenisName}" berikut, pastikan semua form dengan tanda ( * ) terisi, kemudian silhkan klik "Simpan" untuk menyelesaikan Pendaftaran!`);
                break;
            case formData.userJenisID === "5" && formData.groupID === "1" && (formData.unitJenisID === "1" || formData.unitJenisID === "2") && formData.accountType === "createNew":
                setAlertType("warning");
                setAlertMessage(`Pendaftaran ${formData.unitJenisName} Baru`);
                setAlertDescription(`Silahkan lengkapi form pendaftaran Akun "${formData.userJenisName}" berikut, pastikan semua form dengan tanda ( * ) terisi, kemudian silhkan klik "Simpan" untuk menyelesaikan Pendaftaran!`);
                break;
            default:
                setStepButtonDisabled(false);
                setAlertType("warning");
                setAlertMessage("Pendaftaran");
                setAlertDescription(`Anda akan melakukan serangkaian proses pendaftaran. Wajib untuk menyelesaikan sampai step terakhir, jika tidak maka pendaftaran yang anda lakukan tidak akan tersimpan oleh system.  Silahkan ikuti step berikut!`);
                break;
        }

        if (formData.personalName === "" || formData.userName === "" || formData.userEmail === "" || formData.userPassword === "") return setStepButtonDisabled(true);
        setStepButtonDisabled(false);
    }
};