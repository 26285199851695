import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { 
    MenuFoldOutlined,
    CopyrightOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    PoweroffOutlined
} from '@ant-design/icons';
import { 
    Menu, 
    Image,
    Space, 
    Layout, 
    Button, 
    Typography,
} from 'antd';
import { sidebarItems, navbarItems, rootMenuKeys, BreadCrumb } from "../Routes";

import { userLogout } from "../_services";
import { toTitleCase } from "../_helpers";

import "./pageLayout.css";
import digitrenLogo from "../Assets/digitren-logo.png";

const { 
    Header, 
    Content, 
    Sider, 
    Footer 
} = Layout;
const { Text } = Typography;

export const PageLayout = () => {
    const { user_nama } = JSON.parse(localStorage.getItem("userCredentials"));

    const navigate = useNavigate();
    const date = new Date();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [openKeys, setOpenKeys] = useState(['dashboard']);
    const [windowDimension, setWindowDiemnsion] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const currentMenuKeys = useMemo(() => {
        const splitPath = location.pathname.split("/");
        return splitPath[1];
    }, [location]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    
        if (rootMenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    function logOut() {
        localStorage.removeItem('user');
        navigate("/login");
    };

    const navbarNavigation = (e) => {
        switch (e.key) {
            case "setting":
                navigate("/setting");
                break;
            case "logout":
                userLogout(navigate);
                break;
            default:
                break;
        }
    };

    const getSize = () => {
        setWindowDiemnsion({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        window.addEventListener('resize', getSize);
        if (windowDimension.width < 1024 && collapsed === false) {
            setCollapsed(true);
        }
        else if (windowDimension.width > 1024 && collapsed === true) {
            setCollapsed(false);
        }
        return () => {
            window.removeEventListener('resize', getSize);
        };
    }, [windowDimension.width]);

    return (
        <>
        <Layout style={{ height: "100%" }}>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className={collapsed === false ? "logoUnfold" : "logoFold"} >
                    <Image width={30} height={35} src={digitrenLogo} preview={false} />
                    <Text className={collapsed === false ? "logoTextUnfold" : "logoTextFold"}>Digitren</Text>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={currentMenuKeys}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    items={sidebarItems}
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background">
                    <div className="burger-button-container">
                        <Button 
                            style={{ backgroundColor: "transparent", borderColor: "transparent" }}
                            onClick={() => {
                                setCollapsed(!collapsed);
                            }}
                        >
                            {
                                collapsed === true ? 
                                <>
                                <MenuUnfoldOutlined style={{ color: "white" }} />
                                </> 
                                : 
                                <>
                                <MenuFoldOutlined style={{ color: "white" }} />
                                </>
                            }
                        </Button>
                    </div>
                    <div className="navbar-item-container">
                        <Menu 
                            theme="dark" 
                            mode="horizontal"
                            className="navbar-items" 
                            // style={{ display: "flex" }}
                            triggerSubMenuAction={"click"}
                            items={navbarItems()}
                            onClick={(e) => {
                                navbarNavigation(e);
                            }} 
                        />
                    </div>
                </Header>
                <Content className="site-layout-container">
                    {/* <div className="breadcrumb-container">
                        <BreadCrumb />
                        <Text style={{ textTransform: "capitalize" }}>{user_nama}</Text>
                    </div> */}
                    <BreadCrumb />
                    <Outlet />
                </Content>
                <Footer className="footer">
                    <Space direction="horizontal">
                        <Text style={{ textTransform: "capitalize" }}>{user_nama} | </Text>
                        <Text>{date.getFullYear()}</Text>
                        <CopyrightOutlined />
                        <Text>Digitren</Text>
                    </Space>
                </Footer>
            </Layout>
        </Layout>
        </>
    );
};