import React, { useMemo, useState } from "react";
import { 
    Button, 
    Modal,
    Space,
    Select,
    Typography,
    Result
} from 'antd';

import { exportToExcel } from "../../_helpers";

import "./_k.css";

const { Option } = Select;
const { Text } = Typography;

export const ModalExcelDataSetting = ({modalExcelDataSettingOpen, setModalExcelDataSettingOpen, selectedLembaga}) => {
    const { unit_nama, unit_id } = JSON.parse(localStorage.getItem("userCredentials")); 
    const userAndRole = JSON.parse(localStorage.getItem("userAndRole"));
    const periode = JSON.parse(localStorage.getItem("periode"));
    const periodeJenis = JSON.parse(localStorage.getItem("periodeJenis"));
    const siswa = JSON.parse(localStorage.getItem("siswaAll"));
    const komponen = JSON.parse(localStorage.getItem("komponen"));

    const [periodeData, setPeriodeData] = useState([]);
    const [selectedPeriode, setSelectedPeriode] = useState(undefined);
    const [selectedPeriodeJenis, setSelectedPeriodeJenis] = useState(undefined);

    const getUnitID = useMemo(() => {
        return userAndRole.filter((data) => data.vUnitNama === selectedLembaga)[0]?.vUnitID;
    }, [selectedLembaga]);

    const dataKomponen = useMemo(() => {
        return komponen.filter((data) => data.unit_id === (getUnitID === undefined ? unit_id : getUnitID));
    }, [selectedLembaga, unit_id]);

    const dataPeriode = useMemo(() => {
        return periode.filter((data) => data.unit_id === (getUnitID === undefined ? unit_id : getUnitID));
    }, [selectedLembaga, unit_id]);

    const dataSiswa = useMemo(() => {
        const data = selectedLembaga === undefined ? siswa.filter((data) => data.lembaga.includes(unit_nama)) : siswa.filter((data) => data.lembaga.includes(selectedLembaga));
        return data;
    }, [selectedLembaga, selectedPeriode]);

    // console.log("UNIT ID: ", getUnitID);
    // console.log("KOMPONEN DATA: ", dataKomponen);
    // console.log("PERIODE DATA: ", dataPeriode);
    // console.log("SELECTED PERIODE JENIS", selectedPeriodeJenis);

    const intialExcelData = useMemo(() => {
        return dataSiswa.map((data) => {
            return {
                unit_nama: data.lembaga,
                user_nama: data.nama,
                user_no_induk: data.nis,
                periode: selectedPeriode
            }
        });
    }, [selectedPeriode]);

    const excelHeadings = useMemo(() => {
        const tempKomponen = dataKomponen.map((data) => {
            return data.komponen_nama.toLowerCase().split(" ").join("_");
        });
        // return tempKomponen;
        return [[
            "unit_nama",
            "user_nama",
            "user_no_induk",
            "periode",
            ...tempKomponen
        ]]
    }, [dataKomponen]);

    // console.log("EXCEL HEADINGS: ", excelHeadings);

    return (
        <Modal 
            title="Pengaturan Data Excel" 
            open={modalExcelDataSettingOpen} 
            onCancel={() => {
                setSelectedPeriode(undefined);
                setSelectedPeriodeJenis(undefined);
                setModalExcelDataSettingOpen(!modalExcelDataSettingOpen);
            }}
            footer={[
                <Button 
                    key="cancel"
                    type="ghost" 
                    onClick={() => {
                        setSelectedPeriode(undefined);
                        setSelectedPeriodeJenis(undefined);
                        setModalExcelDataSettingOpen(!modalExcelDataSettingOpen);
                    }}
                >
                    Batal
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    disabled={selectedPeriode === undefined ? true : false}
                    onClick={() => {
                        exportToExcel(excelHeadings, intialExcelData, "kewajiban-santri-bulk", "kewajiban-santri-bulk.xlsx", "INPUT KEWAJIBAN", "");
                        setSelectedPeriode(undefined);
                        setSelectedPeriodeJenis(undefined);
                        setModalExcelDataSettingOpen(!modalExcelDataSettingOpen);
                    }}
                >
                    Download Excel
                </Button>,
            ]}
        >
            <div className="modal-body-container">
                <Space direction="vertical" style={{ display: "flex" }}>
                    {
                        dataKomponen.length === 0 || dataPeriode.length === 0 ?
                        <Result
                            status="warning"
                            title={(() => {
                                switch (true) {
                                    case dataKomponen.length === 0 && dataPeriode.length === 0:
                                        return "Data Komponen dan Periode masih kosong, silahkan Buat Komponen dan Periode pada menu Sidebar";
                                    case dataKomponen.length === 0:
                                        return "Data Komponen masih kosong, silahkan Buat Komponen pada menu Sidebar";
                                    case dataPeriode.length === 0:
                                        return "Data Periode masih kosong, silahkan Buat Periode pada menu Sidebar";
                                    default:
                                        return null;
                                }
                            })()}
                        />
                        :
                        <>
                        {/* PERIODE JENIS */}
                        <div className="form-container">
                            <div className="form-label">
                                <Text><Text style={{ color: "red" }}>* </Text>Jenis Periode</Text>
                            </div>
                            <div className="form-input">
                                <Select
                                    allowClear
                                    showSearch
                                    size="large"
                                    className="login-select-field"
                                    placeholder="Pilih Jenis Periode"
                                    optionFilterProp="children"
                                    value={selectedPeriodeJenis}
                                    onChange={(e) => {
                                        setSelectedPeriodeJenis(e);
                                        const dataFilter = dataPeriode.filter((data) => data.periode_jenis_nama.includes(e));
                                        setPeriodeData(dataFilter);
                                        setSelectedPeriode(undefined)
                                        // console.log("FILTER DATA", dataFilter);
                                    }}
                                    filterOption={(input, option) => option.children.props.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {
                                        periodeJenis?.map((items, index) => 
                                            <Option key={items.periode_jenis_id} value={items.periode_jenis_nama}>
                                                <Text style={{ textTransform: "capitalize" }}>{items.periode_jenis_nama}</Text>
                                            </Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                        {/* PERIODE JENIS */}

                        {/* PERIODE SELECT */}
                        {
                            periodeData.length >= 1 && selectedPeriodeJenis !== undefined && 
                            <div className="form-container">
                                <div className="form-label">
                                    <Text><Text style={{ color: "red" }}>* </Text>Periode</Text>
                                </div>
                                <div className="form-input">
                                    <Select
                                        allowClear
                                        showSearch
                                        size="large"
                                        className="login-select-field"
                                        placeholder="Pilih Periode"
                                        optionFilterProp="children"
                                        value={selectedPeriode}
                                        onChange={(e) => {
                                            setSelectedPeriode(e);
                                        }}
                                        filterOption={(input, option) => option.children.props.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {
                                            periodeData?.map((items, index) => 
                                                <Option key={items.periode_id} value={items.periode_nama}>
                                                    <Text>{items.periode_nama}</Text>
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        }
                        {/* PERIODE SELECT */}
                        </>
                    }
                </Space>
            </div>
        </Modal>
    );
};