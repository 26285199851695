import React from "react";
import { 
    DatePicker,
    Typography,
    Select,
    Space
} from 'antd';
import moment from 'moment';
import "moment/locale/id";
import locale from "antd/lib/date-picker/locale/id_ID";

import "./_p.css";

const { Text } = Typography;
const { Option } = Select;

export const PeriodeSelect = ({formData, setFormData}) => {
    switch (formData.periode_jenis_id) {
        case 1:
            return (
                <div className="form-container">
                    <div className="form-label">
                        <Text><Text style={{ color: "red" }}>* </Text>Periode Bulan</Text>
                    </div>
                    <div className="form-input">
                        <DatePicker 
                            size="large"
                            picker="month"
                            locale={locale}
                            placeholder="Pilih Bulan"
                            style={{ width: "100%" }} 
                            onChange={(date, dateString) => {
                                if (date === null) return setFormData({...formData, periode_nama: "", periode_tahun_bulan: ""});
                                const monthToNumber = Number(moment(dateString).format("M"));
                                setFormData({
                                    ...formData, 
                                    periode_nama: `${moment(dateString).format("MMMM")} ${moment(dateString).format("YYYY")}`,
                                    periode_tahun_bulan: `${moment(dateString).format("YYYY")}${monthToNumber >= 10 ? monthToNumber : "0"+monthToNumber}`
                                });
                            }} 
                        />
                    </div>
                </div>
            );
        case 2:
            return (
                <Space direction="vertical" style={{display: "flex"}}>
                    <div className="form-container">
                        <div className="form-label">
                            <Text><Text style={{ color: "red" }}>* </Text>Jenis Semester</Text>
                        </div>
                        <div className="form-input">
                            <Select
                                allowClear
                                showSearch
                                size="large"
                                className="login-select-field"
                                placeholder="Pilih Jenis Semester"
                                optionFilterProp="children"
                                value={formData.periodeJenisSemesterName}
                                onChange={(e) => {
                                    const periodeSemesterName = e.split("_")[0];
                                    const periodeSemesterID = e.split("_")[1];
                                    setFormData({...formData, periodeJenisSemesterName: periodeSemesterName, periodeJenisSemesterID: periodeSemesterID})
                                    // console.log(periodeSemesterName);
                                }}
                                filterOption={(input, option) => option.children.props.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                <Option value={`Gasal_${1}`}>
                                    <Text style={{ textTransform: "capitalize" }}>Gasal</Text>
                                </Option>
                                <Option value={`Genap_${2}`}>
                                    <Text style={{ textTransform: "capitalize" }}>Genap</Text>
                                </Option>
                            </Select>
                        </div>
                    </div>
                    <div className="form-container">
                        <div className="form-label">
                            <Text><Text style={{ color: "red" }}>* </Text>Tahun Semester</Text>
                        </div>
                        <div className="form-input">
                            <DatePicker 
                                size="large"
                                picker="year"
                                locale={locale}
                                placeholder="Pilih Tahun"
                                style={{ width: "100%" }} 
                                onChange={(date, dateString) => {
                                    if (date === null) return setFormData({...formData, periode_nama: "", periode_tahun: ""});
                                    setFormData({
                                        ...formData, 
                                        periode_nama: `${formData.periodeJenisSemesterName} ${moment(dateString).format("YYYY")}`,
                                        periode_tahun_semester: `${moment(dateString).format("YYYY")}${formData.periodeJenisSemesterID}`,
                                    });
                                }} 
                            />
                        </div>
                    </div>
                </Space>
            );
        case 3:
            return (
                <div className="form-container">
                <div className="form-label">
                    <Text><Text style={{ color: "red" }}>* </Text>Periode Tahun</Text>
                </div>
                <div className="form-input">
                    <DatePicker 
                        size="large"
                        picker="year"
                        locale={locale}
                        placeholder="Pilih Tahun"
                        style={{ width: "100%" }} 
                        onChange={(date, dateString) => {
                            if (date === null) return setFormData({...formData, periode_nama: "", periode_tahun: ""});
                            setFormData({
                                ...formData, 
                                periode_nama: `Tahun ${moment(dateString).format("YYYY")}`,
                                periode_tahun: `${moment(dateString).format("YYYY")}`
                            });
                        }} 
                    />
                </div>
                </div>
            );
        default:
            return null;
    }
};