import React from "react";
import { Typography } from "antd";

import "./error.css";

const {Text} = Typography;

export const Error = () => {
    return (
        <>
        <div className="error-body">
            <a href="/" className="error-a">
                <svg height="0.8em" width="0.8em" viewBox="0 0 2 1" preserveAspectRatio="none">
                    <polyline
                        fill="none" 
                        stroke="#777777" 
                        stroke-width="0.1" 
                        points="0.9,0.1 0.1,0.5 0.9,0.9" 
                    />
                </svg> 
                <Text>Home</Text>
            </a>
            <div class="background-wrapper">
                <h1 id="visual">404</h1>
            </div>
            {/* <p className="error-p">The page you’re looking for does not exist.</p> */}
            <p className="error-p">Halaman yang anda cari tidak ditemukan.</p>
        </div>
        </>
    );
};