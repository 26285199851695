import React, { useEffect, useMemo, useState } from "react";
import {  
    Button,
    Upload,
    Dropdown,
    Menu,
    Divider,
    Result
} from "antd";
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { ComponentFilterSearch } from "../ComponentFilterSearch";
import { ComponentTable } from "../ComponentTable";
import { LembagaSelection } from "../../../_Components";
import { ModalAddKomponen } from "../ModalAddKomponen";

export const LowerRolePage = () => {
    const { unit_nama, unit_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const userAndRole = JSON.parse(localStorage.getItem("userAndRole"));
    const komponen = JSON.parse(localStorage.getItem("komponen"));

    const [tableData, setTableData] = useState([]);
    const [dataKomponen, setDataKomponen] = useState([]);
    const [selectedLembaga, setSelectedLembaga] = useState(undefined);
    const [selectedLembagaID, setSelectedLembagaID] = useState(undefined);
    const [modalAddKomponenOpen, setModalAddKomponenOpen] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessHitAPI, setIsSuccessHitAPI] = useState(false);

    useEffect(() => {
        setTableData(komponen.filter((data) => data.unit_id === unit_id));
        setDataKomponen(komponen.filter((data) => data.unit_id === unit_id));
    }, [unit_id, isSuccessHitAPI]);

    return (
        <div className="komponen-page-container">
            <div className="komponen-page-header">
                <div className="komponenLP-page-header-left-side">
                    <Button
                        loading={isLoading}
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        // disabled={selectedLembagaID === undefined ? true : false}
                        onClick={() => {
                            setModalAddKomponenOpen(!modalAddKomponenOpen);
                        }}
                    >
                        Komponen
                    </Button>
                </div>
                <div className="komponenLRP-page-header-right-side">
                    <ComponentFilterSearch 
                        dataPeriode={dataKomponen}
                        dataToSearch={tableData}
                        setTableData={setTableData}
                        placeholder={"Pencarian"}
                    />
                </div>
            </div>

            {
                tableData.length === 0 ?
                <Result
                    status="warning"
                    title={
                        selectedLembaga === undefined ?
                        "Belum ada data Komponen, silahkan Pilih Lembaga naungan Santri"
                        :
                        selectedLembaga !== undefined && tableData.length === 0 &&
                        "Data Komponen masih kosong, silahkan Buat Komponen dengan klik tombol Tambah Komponen"
                    }
                />
                :
                <>
                <Divider orientation="right" style={{ textTransform: "capitalize" }} >{unit_nama}</Divider>
                <div className="table-container">
                    <ComponentTable 
                        tableData={tableData}
                        unitID={unit_id}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setIsSuccessHitAPI={setIsSuccessHitAPI}
                    />
                </div>
                </>
            }

            <ModalAddKomponen
                modalAddKomponenOpen={modalAddKomponenOpen}
                setModalAddKomponenOpen={setModalAddKomponenOpen}
                unitID={unit_id}
                setIsLoading={setIsLoading}
                setIsSuccessHitAPI={setIsSuccessHitAPI}
            />
        </div>
    );
};