import { getUserAndRole, getUnitJenis, getGroup, getUserJenis, getAllOrganization } from "./getAllUserAndRole";
import { getAllKewajiban } from "./kewajibanService";
import { getAllSiswa } from "./siswaService";
import { getPeriodeData, getPeriodeJenis } from "./periodeService";
import { getAllKomponen } from "./komponenService";

export const promiseAll = async () => {
    await Promise.all(
        [
            getUserAndRole(),
            getUnitJenis(),
            getGroup(),
            getUserJenis(),
            getAllOrganization(),
            getAllKewajiban(),
            getAllSiswa(),
            getPeriodeData(), 
            getPeriodeJenis(),
            getAllKomponen()
        ]
    );
};