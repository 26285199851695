import axios from "axios";

import { getAllOrganization } from "./getAllUserAndRole";
import { checkEmptyObjectKeys, openNotification, removeEmptyObjectKeys } from "../_helpers";

export const userRegistration = async (
    payload, 
    setUserCredentials, 
    setGroupOptions,
    setSelectedUserJenis,
    setSelectedGroup,
    setSelectedUnitJenis,
    setShowAlert,
    setSelectedAccountType,
    setIsFormInstitutionNameDisabled
) => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    const finalPayload = removeEmptyObjectKeys(payload);

    console.table(finalPayload);

    try {
        const results = await axios.post(`${process.env.REACT_APP_BASE_PATH}/regist/user`, finalPayload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        openNotification("success", "Sukses", results.data.message);
        setUserCredentials({
            institutionName: "",
            personalName: "", 
            userName: "",
            userEmail: "",
            userPassword: "",
            userJenis: "",
            group: "",
            unitJenis: "",
            pondokID: "",
            subPondokID: "",
            lembagaID: "",
            lembagaLainID: "",
        });
        setGroupOptions([]);
        setSelectedUserJenis(0);
        setSelectedGroup(0);
        setSelectedUnitJenis(0);
        setShowAlert(false);
        setSelectedAccountType("");
        setIsFormInstitutionNameDisabled(true);
        getAllOrganization();
    } catch (error) {
        openNotification("error", "Error", error.response.data.message);
        // console.log(error.response);
    }
};

export const registrationServiceV2 = async (formData, setFormData, setCurrentStep) => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));
    const payload = removeEmptyObjectKeys(formData);
    delete payload["selectedInstitutionName"];
    delete payload["groupSelectionData"];
    delete payload["unitJenisName"];
    delete Object.assign(payload, {["unitJenis"]: payload["unitJenisID"] })["unitJenisID"];
    delete payload["groupName"];
    delete Object.assign(payload, {["group"]: payload["groupID"] })["groupID"];
    delete payload["userJenisName"];
    delete Object.assign(payload, {["userJenis"]: payload["userJenisID"] })["userJenisID"];
    delete payload["accountType"];
    console.log(payload);

    try {
        const results = await axios.post(`${process.env.REACT_APP_BASE_PATH}/regist/user`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        openNotification("success", "Sukses", results.data.message);
        setFormData({
            selectedInstitutionName: undefined,
            institutionName: undefined,
            institutionID: "",
            institutionPhoneNumber: "",
            institutionAddress: "",
            personalName: "", 
            studentIdNumber: "",
            studentName: undefined,
            studentUserID: "",
            employeeIdNumber: "",
            phoneNumber: "",
            userName: "",
            userEmail: "",
            userPassword: "",
            userJenisName: undefined,
            userJenisID: undefined,
            groupName: undefined,
            groupID: undefined,
            groupSelectionData: [],
            unitJenisName: undefined,
            unitJenisID: undefined,
            accountType: undefined,
            pondokID: "",
            subPondokID: "",
            lembagaID: "",
            lembagaLainID: "",
        });
        setCurrentStep(0);
    } catch (error) {
        openNotification("error", "Error", error.response.data.message);
    }

}