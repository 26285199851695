import React, { useEffect, useMemo } from "react";
import { Outlet, Navigate } from "react-router-dom";

import { promiseAll } from "../_services";

export const ProtectedRoutes = () => {

    const isUserExist = useMemo(() => {
        // console.log("CHECK USER IN PROTECTED ROUTES");
        const getUser = localStorage.getItem("userCredentials");
        if (!getUser) return false;
        return true;
    }, []);

    useEffect(() => {
        promiseAll();
    }, []);

    return (
        isUserExist ? <Outlet /> : <Navigate to={"/login"} replace />
    );
};