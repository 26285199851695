import axios from "axios";
import { removeEmptyObjectKeys, openNotification } from "../_helpers";

export const getAllKewajiban = async () => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/finance/kewajiban/find`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        localStorage.setItem("kewajibanAll", JSON.stringify(response.data.data));
        // console.log(response.data.data);
    } catch (error) {
        console.log(error.response);
    }
};

export const postKewajibanBulk = async (payload, setIsLoading, setIsSuccessCreateKewajiban) => {
    setIsLoading(true);
    setIsSuccessCreateKewajiban(false);
    const { token, unit_nama } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/finance/kewajiban/insert_many`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllKewajiban();
        setIsSuccessCreateKewajiban(true);
        setIsLoading(false);
        openNotification("success", "Sukses", "Data Kewajiban berhasil disimpan!");
        // console.log(response.data.data);
    } catch (error) {
        setIsLoading(false);
        openNotification("error", "Gagal", "Data Kewajiban gagal disimpan, coba lagi!");
        // console.log(error.response);
    }
};

export const postTagihanBulk = async (payload, setSelectedTableRowKeys, setSelectedTableRowData, setIsLoading, setIsSuccessCreateKewajiban) => {
    setIsLoading(true);
    setIsSuccessCreateKewajiban(false);
    const { token, unit_nama } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/finance/tagihan/insert_many`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllKewajiban();
        setSelectedTableRowKeys([]);
        setSelectedTableRowData([]);
        setIsLoading(false);
        setIsSuccessCreateKewajiban(true);
        openNotification("success", "Sukses", "Data Tagihan berhasil dibuat!");
        // console.log(response.data.data);
    } catch (error) {
        setIsLoading(false);
        openNotification("error", "Gagal", "Data Tagihan gagal dibuat, coba lagi!");
        // console.log(error.response);
    }
};