import React, { useState } from "react";
import { 
    Button, 
    Modal,
    Space,
    Select,
    Typography,
} from 'antd';

import { PeriodeSelect } from "./PeriodeSelect";

import { addPeriode } from "../../_services";

import "./_p.css";

const { Option } = Select;
const { Text } = Typography;

export const ModalPeriode = ({modalAddPeriodeOpen, setModalAddPeriodeOpen, dataPeriodeJenis, setTableData, getUnitID, setIsLoading, setIsSuccessHitAPI}) => {
    const [formData, setFormData] = useState({
        periode_nama: "",
        periode_jenis_id: undefined,
        periodeJenisSemesterName: undefined,
        periodeJenisSemesterID: "",
        periode_tahun_bulan: "",
        periode_tahun_semester: "",
        periode_tahun: "",
        unit_id: undefined
    });

    const handleCloseModal = () => {
        setFormData({
            periode_nama: "",
            periode_jenis_id: undefined,
            periode_tahun_bulan: "",
            periode_tahun_semester: "",
            periode_tahun: "",
        });
        setModalAddPeriodeOpen(!modalAddPeriodeOpen);
    };
    
    // console.table(formData);

    return (
        <Modal 
            title="Tambah Periode" 
            open={modalAddPeriodeOpen} 
            onCancel={() => {
                handleCloseModal();
            }}
            footer={[
                <Button 
                    key="cancel"
                    type="ghost" 
                    onClick={() => {
                        handleCloseModal();
                    }}
                >
                    Batal
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    disabled={formData.periode_nama === "" ? true : false}
                    onClick={() => {
                        addPeriode(formData, handleCloseModal, setTableData, setIsLoading, setIsSuccessHitAPI);
                    }}
                >
                    Simpan
                </Button>,
            ]}
        >
            <div className="modal-body-container">
                <Space direction="vertical" style={{ display: "flex" }}>

                    {/* PERIODE JENIS */}
                    <div className="form-container">
                        <div className="form-label">
                            <Text><Text style={{ color: "red" }}>* </Text>Jenis Periode</Text>
                        </div>
                        <div className="form-input">
                            <Select
                                allowClear
                                showSearch
                                size="large"
                                className="login-select-field"
                                placeholder="Pilih Jenis Periode"
                                optionFilterProp="children"
                                value={formData.periode_jenis_id}
                                onChange={(e) => {
                                    // console.log(e);
                                    setFormData({
                                        ...formData, 
                                        periode_nama: "",
                                        periode_jenis_id: e,
                                        periodeJenisSemesterName: undefined,
                                        periodeJenisSemesterID: "",
                                        periode_tahun_bulan: "",
                                        periode_tahun_semester: "",
                                        periode_tahun: "",
                                        unit_id: getUnitID
                                    });
                                }}
                                filterOption={(input, option) => option.children.props.children.toLowerCase().includes(input.toLowerCase())}
                            >
                                {
                                    dataPeriodeJenis?.map((items, index) => 
                                        <Option key={items.periode_jenis_id} value={items.periode_jenis_id}>
                                            <Text style={{ textTransform: "capitalize" }}>{items.periode_jenis_nama}</Text>
                                        </Option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>
                    {/* PERIODE JENIS */}

                    {/* PERIODE SELECT */}
                    <PeriodeSelect 
                        formData={formData}
                        setFormData={setFormData}
                    />
                    {/* <div className="form-container">
                        <PeriodeSelect 
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div> */}
                    {/* PERIODE SELECT */}

                    {/* PERIODE FINAL */}
                    {
                        formData.periode_nama !== "" &&
                        <div className="form-container">
                            <div className="form-label">
                                <Text>Periode Kewajiban</Text>
                            </div>
                            <div className="form-input">
                                <Text style={{ fontWeight: "bold" }}>
                                    {formData.periode_nama}
                                </Text>
                            </div>
                        </div>
                    }
                    {/* PERIODE FINAL */}

                </Space>
            </div>
        </Modal>
    );
};