import React, { useMemo } from "react";
import { Badge } from "antd";
import { 
    SmileOutlined,
    MailFilled,
    KeyOutlined, 
    PoweroffOutlined,
    SettingOutlined
} from '@ant-design/icons';

import { Avatar } from 'antd';

const dataMessage = [
    {
        title: 'Ant Design Title 1',
        description: "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections"
    },
    {
        title: 'Ant Design Title 2',
        description: "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections"
    },
    {
        title: 'Ant Design Title 3',
        description: "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections"
    },
    {
        title: 'Ant Design Title 4',
        description: "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections"
    }
];

const messageItems = dataMessage.map((data, index) => {
    return {
        type: "group",
        key: index + 1,
        label: `From : ${data.title}`,
        children: [
            {
                key: `${data.title} Description`,
                label: `${data.description.slice(0, 50)}...`,
                icon: <Avatar src="https://joeschmoe.io/api/v1/random" />,
            }
        ]
    }
});

export const navbarItems = () => {
    const { grup_nama } = JSON.parse(localStorage.getItem("userCredentials"));
    return [
        // {
        //     key: "messages",
        //     label: "",
        //     icon: (
        //         <Badge count={5} size={"small"}>
        //             <MailFilled style={{ color: "white" }} />
        //         </Badge>
        //     ),
        //     children: messageItems
        // },
        {
            key: "user-profile",
            label: grup_nama,
            icon: <SmileOutlined />,
            children: [
                {
                    key: "setting",
                    label: `Setting`,
                    icon: <SettingOutlined />
                },
                {
                    key: "logout",
                    label: "Log Out",
                    icon: <PoweroffOutlined />
                }
            ],
        },
    ]
};