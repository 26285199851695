import React, { useEffect, useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    Dropdown,
    Menu,
    Tooltip
} from "antd";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

export const ComponentFilterSearch = ({dataToSearch, setTableData, placeholder}) => {
    // console.log(dataToSearch);

    const handleSearch = (value) => {
        const searchResults = dataToSearch.filter((items) => 
            items.user_nama?.toLowerCase().includes(value) ||
            items.user_no_induk?.toLowerCase().includes(value) ||
            items.user_kelas?.toLowerCase().includes(value) ||
            items.komponen_nama?.toLowerCase().includes(value) ||
            items.periode_nama?.toLowerCase().includes(value) ||
            items.transaksi_status_nama?.toLowerCase().includes(value) 
        );
        
        if (searchResults.length > 0 && value.length > 0) {
            setTableData(searchResults);
        } else {
            setTableData(dataToSearch);
        }
    };

    return (
        <Search 
            allowClear 
            size="large"
            placeholder={placeholder}
            className="search-input"
            onSearch={handleSearch}
            onChange={(e) => {
                handleSearch(e.target.value);
            }}
        />
    );
};