import React, { useState } from "react";
import { 
    Space,
    Table,
    Button,
    Typography,
    Popconfirm,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const ComponentTable = ({tableData}) => {
    
    const tableColumns = [
        {
            title: "Nama",
            dataIndex: "periode_nama",
        },
        {
            title: "Jenis Periode",
            dataIndex: "periode_jenis_nama",
            render: ((_, {periode_jenis_nama}) => {
                return (
                    <Text style={{ textTransform: "capitalize" }}>{periode_jenis_nama}</Text>
                )
            })
        },
        {
            title: "Tahun Bulan",
            dataIndex: "periode_tahun_bulan",
            render: ((_, {periode_tahun_bulan}) => {
                if (periode_tahun_bulan === null) {
                    return (
                        <Text>-</Text>
                    );
                }
                else {
                    return (
                        <Text>{periode_tahun_bulan}</Text>
                    )
                }
            })
        },
        {
            title: "Tahun Semester",
            dataIndex: "periode_tahun_semester",
            render: ((_, {periode_tahun_semester}) => {
                if (periode_tahun_semester === null) {
                    return (
                        <Text>-</Text>
                    );
                }
                else {
                    return (
                        <Text>{periode_tahun_semester}</Text>
                    )
                }
            })
        },
        {
            title: "Tahun",
            dataIndex: "periode_tahun",
            render: ((_, {periode_tahun}) => {
                if (periode_tahun === null) {
                    return (
                        <Text>-</Text>
                    );
                }
                else {
                    return (
                        <Text>{periode_tahun}</Text>
                    )
                }
            })
        },
    ];

    return (
        <>
            <Table 
                rowKey={(data) => data.periode_id}
                style={{ width: "100%" }} 
                columns={tableColumns} 
                dataSource={tableData}
            />
        </>
    );
};