import React, { useState } from "react";
import { Select, Typography } from "antd";

const { Option } = Select;
const { Text } = Typography;

export const PondokSelection = ({formData, setFormData}) => {
    const allPondok = JSON.parse(localStorage.getItem("allPondok"));

    const handleSelect = (e) => {
        const pondokName = e?.split("_")[0];
        const pondokID = e?.split("_")[1];
        setFormData({...formData, institutionName: pondokName, pondokID: pondokID});
    };

    return (
        <Select
            allowClear
            showSearch
            size="large"
            className="registration-form-input-field"
            placeholder="Pilih Pondok"
            optionFilterProp="children"
            value={formData.institutionName}
            onChange={(e) => {
                handleSelect(e)
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {
                allPondok?.map((items, index) => 
                    <Option key={index} value={`${items.name}_${items.id}`}>{items.name}</Option>
                )
            }
        </Select>
    );
};