import React, { useMemo } from "react";
import { Select, Typography } from "antd";

const { Option } = Select;
const { Text } = Typography;

export const LembagaLainSelectionForRegistration = ({formData, setFormData}) => {
    const { unit_jenis, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const allOrganization = JSON.parse(localStorage.getItem("allOrganization"));
    const allLembagaLain = JSON.parse(localStorage.getItem("allLembagaLain"));

    const lembagaLainSelectionItems = useMemo(() => {
        switch (unit_jenis) {
            case "Platform Digitren":
                return allLembagaLain;
            case "Pondok":
                const getSubPondok = allOrganization.filter((data) => data.id === unit_tabel_id).map((data) => data.detailSubPondok);
                let allLembagaLainByPondokID = [];
                const getLembagaLain = getSubPondok[0]?.map((data) => data.detailLembagaLain);
                if (getLembagaLain !== undefined) {
                    getLembagaLain.forEach(data => {
                        data.forEach(dataX => {
                            allLembagaLainByPondokID.push({
                                id: dataX.id,
                                subPondokID: dataX.subPondokID,
                                name: dataX.lembagaLainNama,
                            });
                        })
                    });
                }
                return allLembagaLainByPondokID;
            case "Sub Pondok":
                return allLembagaLain.filter((data) => data.subPondokID === unit_tabel_id);
            case "Lembaga":
                return allLembagaLain.filter((data) => data.subPondokID === unit_tabel_id);
            default:
                break;
        }
    }, [unit_jenis, unit_tabel_id, allLembagaLain, allOrganization]);

    const handleSelect = (e) => {
        const lembagaLainName = e?.split("_")[0];
        const lembagaLainID = e?.split("_")[1];
        setFormData({...formData, institutionName: lembagaLainName, lembagaLainID});
    };

    return (
        <Select
            showSearch
            size="large"
            className="registration-select-filed"
            placeholder="Pilih Lembaga Lain"
            optionFilterProp="children"
            value={formData?.institutionName}
            onChange={(e) => {
                handleSelect(e);
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {
                lembagaLainSelectionItems?.map((items, index) => 
                    <Option key={index} value={`${items.name}_${items.id}`}>{items.name}</Option>
                )
            }
        </Select>
    );
};