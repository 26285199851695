import React, { useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    TreeSelect
} from "antd";
import { read, utils, writeFile } from 'xlsx';
import { EyeOutlined } from "@ant-design/icons";

import { AdminFinanceLembaga } from "./UserRole/AdminFinanceLembaga";
import { LowerRolePage } from "./UserRole/LowerRolePage";
import { UpperRolePage } from "./UserRole/UpperRolePage";

import { UnitSelection, LembagaSelection } from "../../_Components";

import "./_k.css";

const { TreeNode } = TreeSelect

export const Kewajiban = () => {
    const { unit_jenis } = JSON.parse(localStorage.getItem("userCredentials"));
    // console.log(allOrganization);

    switch (unit_jenis) {
        case "Platform Digitren":
            return (
                <UpperRolePage />
            );
        case "Pondok":
            return (
                <UpperRolePage />
            );
        case "Sub Pondok":
            return (
                <UpperRolePage />
            );
        case "Lembaga":
            return (
                <LowerRolePage />
            );
        case "Lembaga Lain":
            return (
                <LowerRolePage />
            );
        default:
            return null;
    }
};