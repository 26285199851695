import React, { useEffect, useMemo, useState } from "react";
import { 
    Alert,
    Button,
    Divider,
    Input,
    Radio,
    Typography
} from "antd";

import { 
    PondokSelection, 
    SubPondokSelection, 
    LembagaSelectionForRegistration ,
    LembagaLainSelectionForRegistration,
} from "../../../_Components";

const { Text } = Typography;
const { TextArea } = Input;

export const FormInstitutionRegistration = ({formData, setFormData}) => {

    const setDisabledFormInput = useMemo(() => {
        switch (true) {
            case (formData.userJenisID === "5" && formData.groupID !== "1" && formData.unitJenisID === "1") || (formData.userJenisID === "5" && formData.groupID === "1" && formData.unitJenisID === "1" && formData.accountType === "addAccount"):
                return true;
            case (formData.userJenisID === "5" && formData.groupID !== "1" && formData.unitJenisID === "2") || (formData.userJenisID === "5" && formData.groupID === "1" && formData.unitJenisID === "2" && formData.accountType === "addAccount"):
                return true;
            case formData.userJenisID === "5" && formData.groupID === "1" && formData.unitJenisID === "3" && formData.accountType === "addAccount":
                return true;
            case (formData.userJenisID === "5" && formData.groupID === "1" && formData.unitJenisID === "4" && formData.accountType === "addAccount") || (formData.userJenisID === "5" && (formData.groupID === "2" || formData.groupID === "3" || formData.groupID === "4") && (formData.unitJenisID === "3" || formData.unitJenisID === "4")):
                return true;
            case formData.userJenisID !== "5" && formData.groupID !== undefined && (formData.unitJenisID === "3" || formData.unitJenisID === "4"):
                return true;
            default:
                return false;
        };
    }, [formData])

    const InstitutionSelection = () => {
        switch (true) {
            case (formData.userJenisID === "5" && formData.groupID === "1" && formData.unitJenisID === "4" && formData.accountType === "addAccount") || (formData.userJenisID === "5" && (formData.groupID === "2" || formData.groupID === "3" || formData.groupID === "4") && formData.unitJenisID === "4") || (formData.userJenisID !== "5" && formData.groupID !== undefined && formData.unitJenisID === "4"):
                return (
                    <div className="form-select-container">
                        <div className="form-select-label">
                            <Text><Text style={{ color: "red" }}>* </Text>Pilih {formData?.unitJenisName}</Text>
                        </div>
                        <div className="form-select">
                            <LembagaLainSelectionForRegistration
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
        <InstitutionSelection />
        <div className="form-select-container">
            <div className="form-select-label">
                <Text><Text style={{ color: "red" }}>* </Text>Nama {formData?.unitJenisName}</Text>
            </div>
            <div className="form-select">
                <Input
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Nama ${formData?.unitJenisName}`}
                    value={formData?.institutionName}
                    disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, institutionName: e.target.value});
                    }}
                />
            </div>
        </div>
        <div className="form-select-container">
            <div className="form-select-label">
                <Text>Nomor Induk {formData?.unitJenisName}</Text>
            </div>
            <div className="form-select">
                <Input
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Nomor Induk ${formData?.unitJenisName}`}
                    value={formData?.institutionID}
                    disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, institutionID: e.target.value});
                    }}
                />
            </div>
        </div>
        <div className="form-select-container">
            <div className="form-select-label">
                <Text>Nomor Telepon {formData?.unitJenisName}</Text>
            </div>
            <div className="form-select">
                <Input
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Nomor Telepon ${formData?.unitJenisName}`}
                    value={formData?.institutionPhoneNumber}
                    disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, institutionPhoneNumber: e.target.value});
                    }}
                />
            </div>
        </div>
        <div className="form-select-container">
            <div className="form-select-label">
                <Text>Alamat {formData?.unitJenisName}</Text>
            </div>
            <div className="form-select">
                <TextArea 
                    rows={2} 
                    maxLength={200} 
                    disabled={setDisabledFormInput}
                    placeholder={`Input Alamat ${formData?.unitJenisName}`}
                    className="registration-form-input-field"
                    value={formData?.institutionAddress}
                    onChange={(e) => {
                        setFormData({...formData, institutionAddress: e.target.value});
                    }}
                />
            </div>
        </div>
        </>
    );
};