import React, { useEffect, useMemo, useState } from "react";
import {  
    Button,
    Upload,
    Dropdown,
    Menu,
    Divider,
    Result
} from "antd";
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { ComponentFilterSearch } from "../ComponentFilterSearch";
import { ModalPeriode } from "../ModalPeriode";
import { ComponentTable } from "../ComponentTable";
import { LembagaSelection } from "../../../_Components";

export const UpperRolePage = () => {
    const userAndRole = JSON.parse(localStorage.getItem("userAndRole"));
    const periode = JSON.parse(localStorage.getItem("periode"));
    const periodeJenis = JSON.parse(localStorage.getItem("periodeJenis"));

    const [tableData, setTableData] = useState([]);
    const [dataPeriode, setDataPeriode] = useState([]);
    const [selectedLembaga, setSelectedLembaga] = useState(undefined);
    const [selectedLembagaID, setSelectedLembagaID] = useState(undefined);
    const [dataPeriodeJenis, setDataPeriodeJenis] = useState(periodeJenis);
    const [modalAddPeriodeOpen, setModalAddPeriodeOpen] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessHitAPI, setIsSuccessHitAPI] = useState(false);

    const getUnitID = useMemo(() => {
        return userAndRole.filter((data) => data.vUnitNama === selectedLembaga)[0]?.vUnitID;
    }, [selectedLembaga, selectedLembagaID, isSuccessHitAPI]);

    useEffect(() => {
        setTableData(periode.filter((data) => data.unit_id === getUnitID));
        setDataPeriode(periode.filter((data) => data.unit_id === getUnitID));
    }, [getUnitID, isSuccessHitAPI]);

    // console.log(getUnitID);
    
    return (
        <div className="periode-page-container">
            <div className="periode-page-header">
                <div className="periode-page-header-left-side">
                    <Button
                        loading={isLoading}
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setModalAddPeriodeOpen(!modalAddPeriodeOpen);
                        }}
                    >
                        Periode
                    </Button>
                </div>
                <div className="periode-page-header-middle-side">
                    <LembagaSelection 
                        selectedLembaga={selectedLembaga}
                        setSelectedLembaga={setSelectedLembaga}
                        selectedLembagaID={selectedLembagaID}
                        setSelectedLembagaID={setSelectedLembagaID}
                    />
                </div>
                <div className="periode-page-header-right-side">
                    <ComponentFilterSearch 
                        dataPeriode={dataPeriode}
                        dataToSearch={tableData}
                        setTableData={setTableData}
                        placeholder={"Pencarian"}
                    />
                </div>
            </div>

            {
                tableData.length === 0 ?
                <Result
                    status="warning"
                    title={
                        selectedLembaga === undefined ?
                        "Belum ada data Periode, silahkan Pilih Lembaga naungan Santri"
                        :
                        selectedLembaga !== undefined && tableData.length === 0 &&
                        "Data Periode masih kosong, silahkan Buat Periode dengan klik tombol Tambah Periode"
                    }
                />
                :
                <>
                <Divider orientation="right" style={{ textTransform: "capitalize" }} >{selectedLembaga}</Divider>
                <div className="table-container">
                    <ComponentTable 
                        tableData={tableData}
                    />
                </div>
                </>
            }

            <ModalPeriode 
                modalAddPeriodeOpen={modalAddPeriodeOpen}
                setModalAddPeriodeOpen={setModalAddPeriodeOpen}
                dataPeriodeJenis={dataPeriodeJenis}
                setDataPeriode={setTableData}
                getUnitID={getUnitID}
                setIsLoading={setIsLoading}
                setIsSuccessHitAPI={setIsSuccessHitAPI}
            />
        </div>
    );
};