import React, { useState } from "react";
import { 
    Space,
    Table,
    Button,
    Typography,
    Popconfirm,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { ModalEditKomponen } from "./ModalEditKomponen";

import { deleteKomponen } from "../../_services";

const { Text } = Typography;

export const ComponentTable = ({tableData, isLoading, setIsLoading, setIsSuccessHitAPI}) => {
    const [modalEditKomponenOpen, setModalEditKomponenOpen] = useState(false);
    const [formEditData, setFormEditData] = useState({
        komponen_id: undefined,
        nama_komponen: undefined,
        unit_id: undefined,
    });
    // console.log(formEditData);
    
    const tableColumns = [
        {
            title: "Nama Komponen",
            dataIndex: "komponen_nama",
        },
        {
            title: "Action",
            dataIndex: "key",
            render: ((_, data) => 
                <Space direction="horizontal" >
                    <Button
                        className="button-custom-warning"
                        type="ghost"
                        shape="circle" 
                        icon={<EditOutlined style={{ color: "white" }} />} 
                        onClick={() => {
                            setFormEditData({
                                komponen_id: data.komponen_id,
                                nama_komponen: data.komponen_nama,
                                unit_id: data.unit_id,
                            });
                            setModalEditKomponenOpen(!modalEditKomponenOpen);
                        }}
                    />
                    <Popconfirm
                        placement="topRight"
                        title={`Anda akan menghapus Komponen "${data.komponen_nama}".  Apakah anda Yakin?`}
                        onConfirm={() => {
                            deleteKomponen(data.komponen_id, setIsLoading, setIsSuccessHitAPI);
                        }}
                        okText="Ya"
                        cancelText="Batal"
                    >
                        <Button
                            loading={isLoading}
                            type="danger"
                            shape="circle" 
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                </Space>
            )
        }
    ];

    return (
        <>
            <Table 
                rowKey={(data) => data.komponen_id}
                style={{ width: "100%" }} 
                columns={tableColumns} 
                dataSource={tableData}
            />

            <ModalEditKomponen 
                formEditData={formEditData}
                setFormEditData={setFormEditData}
                modalEditKomponenOpen={modalEditKomponenOpen}
                setModalEditKomponenOpen={setModalEditKomponenOpen}
                setIsLoading={setIsLoading}
                setIsSuccessHitAPI={setIsSuccessHitAPI}
            />
        </>
    );
};