import React, { useMemo, useState } from "react";
import { Select, Typography } from "antd";

const { Option } = Select;
const { Text } = Typography;

export const SubPondokSelection = ({formData, setFormData}) => {
    const { unit_jenis, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const allOrganization = JSON.parse(localStorage.getItem("allOrganization"));
    const allSubPondok = JSON.parse(localStorage.getItem("allSubPondok"));

    const subPondokSelectionItems = useMemo(() => {
        switch (unit_jenis) {
            case "Platform Digitren":
                return allSubPondok;
            case "Pondok":
                let subPondokByPondokID = [];
                const getSubPondok = allOrganization.filter((data) => data.id === unit_tabel_id).map((data) => data.detailSubPondok);
                getSubPondok.forEach(data => {
                    data.forEach(dataX => {
                        subPondokByPondokID.push({
                            id: dataX.id,
                            pondokID: dataX.pondokID,
                            name: dataX.subPondokNama,
                        })
                    })
                });
                return subPondokByPondokID;
            default:
                break;
        }
    }, [unit_jenis, unit_tabel_id, allSubPondok, allOrganization]);

    const handleSelect = (e) => {
        const subPondokName = e?.split("_")[0];
        const subPondokID = e?.split("_")[1];
        if (formData.userJenisID === "5" && formData.groupID === "1" && (formData.unitJenisID === "3" || formData.unitJenisID === "4") && formData.accountType === "createNew") return setFormData({...formData, selectedInstitutionName: subPondokName, subPondokID: subPondokID});
        setFormData({...formData, selectedInstitutionName: subPondokName, institutionName: subPondokName, subPondokID: subPondokID});
    };
    // console.log(subPondokName);

    return (
        <Select
            allowClear
            showSearch
            size="large"
            className="registration-form-input-field"
            placeholder="Pilih Sub Pondok"
            optionFilterProp="children"
            value={formData.selectedInstitutionName}
            onChange={(e) => {
                handleSelect(e);
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {
                subPondokSelectionItems?.map((items, index) => 
                    <Option key={index} value={`${items.name}_${items.id}`}>{items.name}</Option>
                )
            }
        </Select>
    );
};