import React, { useMemo } from "react";
import { Select } from "antd";

const { Option } = Select;

export const UserJenisSelection = ({showSearch, formData, setFormData}) => {
    const { unit_jenis, unit_nama, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const allUserJenis = JSON.parse(localStorage.getItem("userJenis"));
    const allGroup = JSON.parse(localStorage.getItem("group"));

    const userJenisOptions = useMemo(() => {
        switch (true) {
            case unit_jenis === "Lembaga":
                return allUserJenis.filter((data) => data.userJenisNama !== "Siswa Lain");
            case unit_jenis === "Lembaga Lain":
                return allUserJenis.filter((data) => data.userJenisNama !== "Siswa");
            default:
                return allUserJenis;
        }
    }, [unit_jenis, allUserJenis])

    const handleSelectedUserJenis = (event) => {
        const selectedName = event.split("_")[0];
        const selectedID = event.split("_")[1];
        const userRole = allGroup.filter((data) => data.groupNama.toLowerCase().replace(" ", "").includes(selectedName.split(" ")[0].toLowerCase()));
        if (userRole.length > 1) {
            setFormData({
                ...formData,
                selectedInstitutionName: undefined,
                institutionName: undefined,
                institutionID: "",
                institutionPhoneNumber: "",
                institutionAddress: "",
                personalName: "", 
                studentIdNumber: "",
                studentName: undefined,
                studentUserID: "",
                employeeIdNumber: "",
                userName: "",
                userEmail: "",
                userPassword: "",
                userJenisName: selectedName, 
                userJenisID: selectedID,
                groupName: undefined,
                groupID: undefined,
                groupSelectionData: userRole,
                unitJenisName: undefined,
                unitJenisID: undefined,
                accountType: undefined,
            });
        }
        // else if (unit_jenis === "Lembaga") {
        //     setFormData({
        //         ...formData,
        //         selectedInstitutionName: undefined,
        //         institutionName: undefined,
        //         institutionID: "",
        //         institutionPhoneNumber: "",
        //         institutionAddress: "",
        //         personalName: "", 
        //         studentIdNumber: "",
        //         studentName: undefined,
        //         studentUserID: "",
        //         employeeIdNumber: "",
        //         userName: "",
        //         userEmail: "",
        //         userPassword: "",
        //         userJenisName: selectedName, 
        //         userJenisID: selectedID,
        //         groupName: userRole[0].groupNama,
        //         groupID: userRole[0].id.toString(),
        //         groupSelectionData: [],
        //         unitJenisName: selectedID === "5" ? "Lembaga" : undefined,
        //         unitJenisID: selectedID === "2" ? "3" : selectedID === "3" ? "4" : undefined,
        //         accountType: undefined
        //     });
        // }
        else {
            setFormData({
                ...formData,
                selectedInstitutionName: undefined,
                institutionName: undefined,
                institutionID: "",
                institutionPhoneNumber: "",
                institutionAddress: "",
                personalName: "", 
                studentIdNumber: "",
                employeeIdNumber: "",
                userName: "",
                userEmail: "",
                userPassword: "",
                userJenisName: selectedName, 
                userJenisID: selectedID,
                groupName: userRole[0].groupNama,
                groupID: userRole[0].id.toString(),
                groupSelectionData: [],
                unitJenisName: (selectedID === "2" || (unit_jenis === "Lembaga" && (selectedID === "4" || selectedID === "1"))) ? "Lembaga" : (selectedID === "3" || (unit_jenis === "Lembaga Lain" && (selectedID === "4" || selectedID === "1"))) ? "Lembaga Lain" : undefined,
                unitJenisID: (selectedID === "2" || (unit_jenis === "Lembaga" && (selectedID === "4" || selectedID === "1"))) ? "3" : (selectedID === "3" || (unit_jenis === "Lembaga Lain" && (selectedID === "4" || selectedID === "1"))) ? "4" : undefined,
                accountType: undefined
            });
        }
    };

    return (
        <Select
            size="large"
            placeholder="Pilih User Jenis"
            className="registration-page-selection"
            showSearch={showSearch}
            optionFilterProp="children"
            value={formData.userJenisName}
            onChange={(e) => {
                handleSelectedUserJenis(e);
            }}
        >
            {
                userJenisOptions?.map((items, index) => 
                    <Option key={index} value={`${items.userJenisNama}_${items.id}`}>{items.userJenisNama}</Option>
                )
            }
        </Select>
    );
};