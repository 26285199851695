import React, { useEffect, useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    Dropdown,
    Menu,
    Tooltip,
    Alert,
    Divider,
    Result,
    Card
} from "antd";
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { importFromExcel } from "../../../_helpers";
import { postKewajibanBulk, postTagihanBulk } from "../../../_services";

import { ComponentCard } from "../ComponentCard";
import { ComponentTable } from "../ComponentTable";
import { ComponentFilterSearch } from "../ComponentFilterSearch";
import { ModalExcelDataSetting } from "../ModalExcelDataSetting";

export const LowerRolePage = () => {
    const { unit_nama } = JSON.parse(localStorage.getItem("userCredentials")); 
    const kewajibanAll = JSON.parse(localStorage.getItem("kewajibanAll"));

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [importedRowsData, setImportedRowsData] = useState([]);
    const [selectedTableRowData, setSelectedTableRowData] = useState([]);
    const [selectedTableRowKeys, setSelectedTableRowKeys] = useState([]);
    const [modalExcelDataSettingOpen, setModalExcelDataSettingOpen] = useState(false);
    const [isSuccessCreateKewajiban, setIsSuccessCreateKewajiban] = useState(false); 

    const dataKewajiban = useMemo(() => {
        return kewajibanAll.filter((data) => data.unit_nama.includes(unit_nama));
    }, [isSuccessCreateKewajiban]);

    useEffect(() => {
        setTableData(dataKewajiban);
    }, [isSuccessCreateKewajiban]);
    // console.log(selectedTableRowData);
    // console.log("TABLE DATA", tableData);

    const dropdownMenu = (
        <Menu 
            items={[
                {
                    key: "export",
                    label: (
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                setModalExcelDataSettingOpen(!modalExcelDataSettingOpen);
                            }}
                        >
                            Export Excel
                        </Button>
                    ),
                },
                {
                    key: "import",
                    label: (
                        <Upload
                            showUploadList={false}
                            onChange={(e) => {
                                importFromExcel(e, setImportedRowsData);
                                if (importedRowsData.length >= 1) return postKewajibanBulk(importedRowsData, setIsLoading, setIsSuccessCreateKewajiban);
                            }}
                        >
                            <Button
                                type="text"
                                icon={<UploadOutlined />}
                                onClick={() => {
                                    // console.log("IMPORT");
                                }}
                            >
                                Import EXCEL
                            </Button>
                        </Upload>
                    ),
                }
            ]}
        />
    );

    return (
        <div className="main-page-container">
            <div className="lower-role-page-heading-container">
                <div className="lower-role-page-header-left-side">
                    <Space direction="horizontal">
                        <Dropdown
                            // disabled={selectedLembagaID === undefined ? true : false}
                            overlay={dropdownMenu} 
                            placement="bottomLeft" 
                            trigger="click"
                            arrow
                        >
                            <Button
                                loading={isLoading}
                                size="large"
                                type="primary"
                                icon={<PlusOutlined />}
                            >
                                Kewajiban
                            </Button>
                        </Dropdown>
                        <Tooltip 
                            placement="bottomLeft"
                            title={selectedTableRowData.length === 0 ? "Silahkan pilih Kewajiban pada tabel dibawah dengan status Menunggu Tagihan" : ""}
                        >
                            <Button
                                loading={isLoading}
                                size="large"
                                type="primary"
                                disabled={selectedTableRowData.length === 0 || isLoading === true ? true : false}
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    postTagihanBulk(selectedTableRowData, setSelectedTableRowKeys, setSelectedTableRowData, setIsLoading, setIsSuccessCreateKewajiban);
                                }}
                            >
                                Tagihan
                            </Button>
                        </Tooltip>
                    </Space>
                </div>
                <div className="lower-role-page-header-right-side">
                    <div className="lower-role-search-container">
                        <ComponentFilterSearch 
                            dataToSearch={dataKewajiban}
                            setTableData={setTableData}
                            placeholder={"Pencarian"}
                        />
                    </div>
                </div>
            </div>
            <div className="table-container">
                {
                    tableData.length === 0 ? 
                    <Result
                        status="warning"
                        title={`Data Kewajiban masih kosong, silahkan "Tambah Kewajiban" dengan klik tombol "+ Kewajiban"`}
                    />
                    : 
                    <>
                    <Divider orientation="right" style={{ textTransform: "capitalize" }} >{unit_nama}</Divider>
                    {/* <ComponentCard 
                        tableData={tableData}
                    /> */}
                    <ComponentTable 
                        tableData={tableData}
                        setSelectedTableRowData={setSelectedTableRowData}
                        selectedTableRowKeys={selectedTableRowKeys}
                        setSelectedTableRowKeys={setSelectedTableRowKeys}
                    />
                    </>
                }
            </div>

            <ModalExcelDataSetting 
                modalExcelDataSettingOpen={modalExcelDataSettingOpen}
                setModalExcelDataSettingOpen={setModalExcelDataSettingOpen}
            />
        </div>
    );
};