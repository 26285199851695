import axios from "axios";
import { openNotification } from "../_helpers";

export const userLogin = async (userCredentials, navigate) => {
    const { userName, password, userJenisID, vUnitID } = userCredentials;

    const sadf5473rthfv = 5;

    const payload = {
        user_name: userName,
        user_password: password,
    };

    try {
        // const results = await axios.post(`${process.env.REACT_APP_BASE_PATH}/login/${userJenisID}/${vUnitID}`, payload);
        const results = await axios.post(`${process.env.REACT_APP_BASE_PATH}/login/${sadf5473rthfv}`, payload);
        localStorage.setItem("userCredentials", JSON.stringify(results.data.data));
        openNotification("success", "Login Sukses", null);
        navigate("/dashboard");
        // console.log(results.data.data);
    } catch (error) {
        openNotification("error", "Login Gagal", error.response.data.message);
        // console.log(error.response.data.message);
    }
};

export const userLogout = (navigate) => {
    localStorage.removeItem("userCredentials");
    navigate("/login");
};