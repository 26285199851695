import React from "react";
import { 
    Image,
    Typography, 
} from "antd";
import { Outlet } from "react-router-dom";

import digitrenLogo from "../Assets/digitren-logo.png";

import "./auth.css";

import { Login } from "./Login";

const { Text } = Typography;

export const AuthLayout = () => {
    return (
        <>
        <div className="auth-page-body">
            <div className="auth-page-body-left-side">
                <div className="auth-page-body-left-side-brand">
                    <Image
                        className="brand-logo"
                        src={digitrenLogo}
                        preview={false}
                    />
                    <Text className="brand" style={{ color: "white" }}>D I G I T R E N</Text>
                    <Text className="brand-name" style={{ color: "white" }}>Digital Pesantren Indonesia</Text>
                </div>
            </div>
            <div className="auth-page-body-right-side">
                <div className="login-form-container">
                    <Outlet />
                    {/* <Login /> */}
                </div>
            </div>
        </div>
        </>
    );
};