import React, { useEffect, useState } from "react";
import { 
    Button, 
    Modal,
    Space,
    Select,
    Typography,
    Input
} from 'antd';

import { updateKomponen } from "../../_services";

const { Text } = Typography;

export const ModalEditKomponen = ({formEditData, setFormEditData, modalEditKomponenOpen, setModalEditKomponenOpen, setIsLoading, setIsSuccessHitAPI}) => {
    // console.log("SELECTED DATA TABLE:", formEditData);

    const handleCloseModal = () => {
        // setFormEditData({
        //     nama_komponen: undefined,
        //     unit_id: undefined,
        // });
        setModalEditKomponenOpen(false);
    };

    // useEffect(() => {
    //     setFormEditData({
    //         komponen_id: selectedTableData?.komponen_id,
    //         nama_komponen: selectedTableData?.komponen_nama,
    //         unit_id: selectedTableData?.unit_id,
    //     });
    // }, []);
    // console.table(formEditData);

    return (
        <Modal 
            title="Edit Komponen" 
            open={modalEditKomponenOpen} 
            onCancel={() => {
                handleCloseModal();
            }}
            footer={[
                <Button 
                    key="cancel"
                    type="ghost" 
                    onClick={() => {
                        handleCloseModal();
                    }}
                >
                    Batal
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    disabled={formEditData?.nama_komponen === undefined ? true : false}
                    onClick={() => {
                        updateKomponen(formEditData, handleCloseModal, setIsLoading, setIsSuccessHitAPI);
                    }}
                >
                    Simpan
                </Button>,
            ]}
        >
            <div className="modal-body-container">
                <Space direction="vertical" style={{ display: "flex" }}>

                    <div className="form-container">
                        <div className="form-label">
                            <Text><Text style={{ color: "red" }}>* </Text>Nama Komponen</Text>
                        </div>
                        <div className="form-input">
                            <Input
                                size="large"
                                className="registration-form-input-field"
                                placeholder={`Input Nama Komponen`}
                                value={formEditData?.nama_komponen}
                                onChange={(e) => {
                                    setFormEditData({...formEditData, nama_komponen: e.target.value});
                                }}
                            />
                        </div>
                    </div>

                </Space>
            </div>
        </Modal>
    );
};