import React from "react";

import { UpperRolePage } from "./UserRole/UpperRolePage";
import { LowerRolePage } from "./UserRole/LowerRolePage";

import "./_p.css";

export const Periode = () => {
    const { unit_jenis } = JSON.parse(localStorage.getItem("userCredentials")); 

    switch (unit_jenis) {
        case "Platform Digitren":
            return (
                <UpperRolePage />
            );
        case "Pondok":
            return (
                <UpperRolePage />
            );
        case "Sub Pondok":
            return (
                <UpperRolePage />
            );
        case "Lembaga":
            return (
                <LowerRolePage />
            );
        case "Lembaga Lain":
            return (
                <LowerRolePage />
            );
        default:
            return null;
    };
};