import React, { useEffect, useMemo } from "react";
import { Button, Space, Typography } from "antd";
import { MobileOutlined} from '@ant-design/icons';

import "./dashboard.css";

const { Text } = Typography;

export const Dashboard = () => {

    const institutionName = useMemo(() => {
        const { group_unit_nama } = JSON.parse(localStorage.getItem("userCredentials"));
        return group_unit_nama;
    }, []);

    return (
        <>
        <div className="dashboard-container">
            <Space direction="vertical" style={{ display: "flex" }} >
                <Text style={{ fontSize: "30px", fontWeight: "bold" }}>Selamat Datang</Text>
                <Text style={{ fontSize: "30px", fontWeight: "lighter" }}>{institutionName}</Text>
                <Text style={{ fontSize: "18px", fontWeight: "lighter" }}>
                    Aplikasi mobile bisa download di <a href="https://expo.dev/artifacts/eas/vsSkXtqWs3NdKVpVZbbPZX.apk" target="_blank"><MobileOutlined /></a>     
                </Text>
            </Space>
        </div>
        </>
    );
};