import React, { useMemo } from "react";
import { Select, Typography } from "antd";
import { Group } from "antd/lib/avatar";

const { Option } = Select;
const { Text } = Typography;

export const LembagaSelectionForRegistration = ({formData, setFormData}) => {
    const { unit_jenis, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const allOrganization = JSON.parse(localStorage.getItem("allOrganization"));
    const allLembaga = JSON.parse(localStorage.getItem("allLembaga"));

    const lembagaSelectionItems = useMemo(() => {
        switch (unit_jenis) {
            case "Platform Digitren":
                return allLembaga;
            case "Pondok":
                const getSubPondok = allOrganization.filter((data) => data.id === unit_tabel_id).map((data) => data.detailSubPondok);
                let allLembagaByPondokID = [];
                const getLembaga = getSubPondok[0]?.map((data) => data.detailLembaga);
                if (getLembaga !== undefined) {
                    getLembaga.forEach(data => {
                        data.forEach(dataX => {
                            allLembagaByPondokID.push({
                                id: dataX.id,
                                subPondokID: dataX.subPondokID,
                                name: dataX.lembagaNama,
                            });
                        })
                    });
                }
                return allLembagaByPondokID;
            case "Sub Pondok":
                return allLembaga.filter((data) => data.subPondokID === unit_tabel_id);
            case "Lembaga":
                return allLembaga.filter((data) => data.subPondokID === unit_tabel_id);
            default:
                break;
        }
    }, [unit_jenis, unit_tabel_id, allLembaga, allOrganization]);

    const handleSelect = (e) => {
        // group, userJenis, unitJenis
        const lembagaName = e?.split("_")[0];
        const lembagaID = e?.split("_")[1];
        setFormData({...formData, institutionName: lembagaName, lembagaID});
    };

    return (
        <Select
            showSearch
            size="large"
            className="registration-select-filed"
            placeholder="Pilih Lembaga"
            optionFilterProp="children"
            value={formData?.institutionName}
            onChange={(e) => {
                handleSelect(e);
            }}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
            {
                lembagaSelectionItems?.map((items, index) => 
                    <Option key={index} value={`${items.name}_${items.id}`}>{items.name}</Option>
                )
            }
        </Select>
    );
};