import React, { useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    Dropdown,
    Menu,
    Tooltip,
    Alert
} from "antd";
import { read, utils, writeFile } from 'xlsx';
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { importFromExcel } from "../../../_helpers";
import { postKewajibanBulk, postTagihanBulk } from "../../../_services";

import { ComponentTable } from "../ComponentTable";
import { ModalExcelDataSetting } from "../ModalExcelDataSetting";

export const AdminFinanceLembaga = () => {
    const { unit_nama } = JSON.parse(localStorage.getItem("userCredentials")); 
    const kewajibanAll = JSON.parse(localStorage.getItem("kewajibanAll"));

    const dataKewajiban = useMemo(() => {
        return kewajibanAll.filter((data) => data.unit_nama.includes(unit_nama));
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState(dataKewajiban);
    const [importedRowsData, setImportedRowsData] = useState([]);
    const [selectedTableRowData, setSelectedTableRowData] = useState([]);
    const [selectedTableRowKeys, setSelectedTableRowKeys] = useState([]);
    const [modalExcelDataSettingOpen, setModalExcelDataSettingOpen] = useState(false);
    console.log(selectedTableRowData);
    // console.log("TABLE DATA", tableData);

    const dropdownMenu = (
        <Menu 
            items={[
                {
                    key: "export",
                    label: (
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                setModalExcelDataSettingOpen(!modalExcelDataSettingOpen);
                            }}
                        >
                            Export Excel
                        </Button>
                    ),
                },
                {
                    key: "import",
                    label: (
                        <Upload
                            showUploadList={false}
                            onChange={(e) => {
                                importFromExcel(e, setImportedRowsData);
                                if (importedRowsData.length >= 1) return postKewajibanBulk(importedRowsData, setTableData);
                            }}
                        >
                            <Button
                                type="text"
                                icon={<UploadOutlined />}
                                onClick={() => {
                                    // console.log("IMPORT");
                                }}
                            >
                                Import EXCEL
                            </Button>
                        </Upload>
                    ),
                }
            ]}
        />
    );

    return (
        <div className="main-page-container">
            <div className="page-heading-container">
                <Space direction="horizontal">
                    <Dropdown
                        overlay={dropdownMenu} 
                        placement="bottomLeft" 
                        trigger="click"
                        arrow
                    >
                        <Button
                            size="large"
                            type="primary"
                            icon={<PlusOutlined />}
                        >
                            Kewajiban
                        </Button>
                    </Dropdown>
                    <Tooltip 
                        placement="bottomLeft"
                        title={selectedTableRowData.length === 0 ? "Silahkan pilih Kewajiban pada tabel dibawah dengan status Menunggu Tagihan" : ""}
                    >
                        <Button
                            loading={isLoading}
                            size="large"
                            type="primary"
                            disabled={selectedTableRowData.length === 0 || isLoading === true ? true : false}
                            icon={<PlusOutlined />}
                            onClick={() => {
                                postTagihanBulk(selectedTableRowData, setTableData, setSelectedTableRowKeys, setSelectedTableRowData, setIsLoading);
                            }}
                        >
                            Tagihan
                        </Button>
                    </Tooltip>
                </Space>
            </div>
            <div className="table-container">
                {
                    tableData.length === 0 ? 
                    <Alert
                        style={{ width: "320px" }}
                        type="warning"
                        message="Belum ada data! Silahkan buat Kewajiban"
                        showIcon
                    />
                    : 
                    <ComponentTable 
                        tableData={tableData}
                        setSelectedTableRowData={setSelectedTableRowData}
                        selectedTableRowKeys={selectedTableRowKeys}
                        setSelectedTableRowKeys={setSelectedTableRowKeys}
                    />
                }
            </div>

            <ModalExcelDataSetting 
                modalExcelDataSettingOpen={modalExcelDataSettingOpen}
                setModalExcelDataSettingOpen={setModalExcelDataSettingOpen}
            />
        </div>
    );
};