import React, { useMemo } from "react";
import { Select } from "antd";

const { Option } = Select;

export const GroupSelection = ({showSearch, disable, formData, setFormData}) => {
    const { unit_jenis, unit_nama, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const allUserJenis = JSON.parse(localStorage.getItem("userJenis"));
    const allGroup = JSON.parse(localStorage.getItem("group"));

    const handleSelectedGroup = (event) => {
        const selectedName = event.split("_")[0];
        const selectedID = event.split("_")[1];
        if (selectedID !== "1") {
            setFormData({
                ...formData, 
                selectedInstitutionName: undefined,
                institutionName: undefined,
                institutionID: "",
                institutionPhoneNumber: "",
                institutionAddress: "",
                personalName: "", 
                studentIdNumber: "",
                studentName: undefined,
                studentUserID: "",
                employeeIdNumber: "",
                userName: "",
                userEmail: "",
                userPassword: "",
                groupName: selectedName, 
                groupID: selectedID, 
                unitJenisName: unit_jenis === "Lembaga" ? "Lembaga" : unit_jenis === "Lembaga Lain" ? "Lembaga Lain" : undefined,
                unitJenisID: unit_jenis === "Lembaga" ? "3" : unit_jenis === "Lembaga Lain" ? "4" : undefined,
                accountType: undefined
            });
        }
        else {
            setFormData({
                ...formData,
                selectedInstitutionName: undefined,
                institutionName: undefined,
                institutionID: "",
                institutionPhoneNumber: "",
                institutionAddress: "",
                personalName: "", 
                studentIdNumber: "",
                studentName: undefined,
                studentUserID: "",
                employeeIdNumber: "",
                userName: "",
                userEmail: "",
                userPassword: "",
                groupName: selectedName, 
                groupID: selectedID,
                unitJenisName: unit_jenis === "Lembaga" ? "Lembaga" : unit_jenis === "Lembaga Lain" ? "Lembaga Lain" : undefined,
                unitJenisID: unit_jenis === "Lembaga" ? "3" : unit_jenis === "Lembaga Lain" ? "4" : undefined,
                accountType: (unit_jenis === "Lembaga" || unit_jenis === "Lembaga Lain") ? "addAccount" : undefined,
            });
        }
    };

    return (
        <Select
            size="large"
            placeholder="Pilih Group"
            className="registration-page-selection"
            showSearch={showSearch}
            disabled={disable}
            optionFilterProp="children"
            value={formData.groupName}
            onChange={(e) => {
                handleSelectedGroup(e);
            }}
        >
            {
                formData.groupSelectionData?.map((items, index) => 
                    <Option key={index} value={`${items.groupNama}_${items.id}`}>{items.groupNama}</Option>
                )
            }
        </Select>
    );
};