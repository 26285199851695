import React from "react";

// import { RAdminPlatform } from "./R_AdminPlatform";
// import { RAdminPondok } from "./R_AdminPondok";
// import { RAdminSubPondok } from "./R_AdminSubPondok";
// import { RAdminLembaga } from "./R_AdminLembaga";
// import { RAdminLembagaLain } from "./R_AdminLembagaLain";

// import { AccountRegistrationV2 } from "./AccountRegistrationV2";

import AdminPlatform from "./AdminPlatform";
import AdminPondok from "./AdminPondok";
import AdminSubPondok from "./AdminSubPondok";
import AdminLembaga from "./AdminLembaga";
import AdminLembagaLain from "./AdminLembagaLain";

import "./_ar.css";

export const AccountRegistration = () => {
    const { unit_jenis } = JSON.parse(localStorage.getItem("userCredentials"));

    switch (true) {
        case unit_jenis === "Platform Digitren":
            return (
                <AdminPlatform />
            );
        case unit_jenis === "Pondok":
            return (
                <AdminPondok />
            );
        case unit_jenis === "Sub Pondok":
            return (
                <AdminSubPondok />
            );
        case unit_jenis === "Lembaga":
            return (
                <AdminLembaga />
            );
        case unit_jenis === "Lembaga Lain":
            return (
                <AdminLembagaLain />
            );
        default:
            break;
    }

    // switch (true) {
    //     case unit_jenis === "Platform Digitren":
    //         return (
    //             <RAdminPlatform />
    //         );
    //     case unit_jenis === "Pondok":
    //         return (
    //             <RAdminPondok />
    //         );
    //     case unit_jenis === "Sub Pondok":
    //         return (
    //             <RAdminSubPondok />
    //         );
    //     case unit_jenis === "Lembaga":
    //         return (
    //             <RAdminLembaga />
    //         );
    //     case unit_jenis === "Lembaga Lain":
    //         return (
    //             <RAdminLembagaLain />
    //         );
    //     default:
    //         break;
    // }
};