import React, { useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    Dropdown,
    Menu,
    Badge,
    Checkbox
} from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { currencyFormatter } from "../../_helpers";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

export const ComponentTable = ({tableData, setSelectedTableRowData, selectedTableRowKeys, setSelectedTableRowKeys}) => {
    // console.log(tableData);

    const rowSelection = {
        selectedRowKeys: selectedTableRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedTableRowData(selectedRows);
            setSelectedTableRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.transaksi_status_nama !== 'Belum Ada Tagihan',
            transaksi_status_nama: record.transaksi_status_nama,
        }),
    };
    
    const tableColumns = [
        {
            title: "Unit",
            dataIndex: "unit_nama",
            showSorterTooltip: false,
        },
        {
            title: "NIS",
            dataIndex: "user_no_induk",
            showSorterTooltip: false,
        },
        {
            title: "Nama Santri",
            dataIndex: "user_nama",
            showSorterTooltip: false,
        },
        {
            title: "Kelas",
            dataIndex: "user_kelas",
            showSorterTooltip: false,
        },
        {
            title: "Periode",
            dataIndex: "periode_nama",
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.periode_nama.length - b.periode_nama.length,
        },
        {
            title: "Komponen",
            dataIndex: "komponen_nama",
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.komponen_nama.length - b.komponen_nama.length,
        },
        {
            title: "Kewajiban",
            dataIndex: "kewajiban_nominal",
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sorter: (a, b) => Number(a.kewajiban_nominal) - Number(b.kewajiban_nominal),
            render: ((_, {kewajiban_nominal}) => (
                <Text>Rp. {currencyFormatter(Number(kewajiban_nominal))}</Text>
            ))
        },
        {
            title: "Tagihan",
            dataIndex: "sum_d",
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sorter: (a, b) => Number(a.sum_d) - Number(b.sum_d),
            render: ((_, {sum_d}) => {
                return (
                    <Text>{sum_d === null ? "-" : "Rp. " + currencyFormatter(Number(sum_d))}</Text>
                )
            })
        },
        {
            title: "Bayar",
            dataIndex: "sum_k",
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sorter: (a, b) => Number(a.sum_k) - Number(b.sum_k),
            render: ((_, {sum_k}) => {
                return (
                    <Text>{sum_k === null ? "-" : "Rp. " + currencyFormatter(Number(sum_k))}</Text>
                )
            })
        },
        {
            title: "Status",
            dataIndex: "transaksi_status_nama",
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.transaksi_status_nama.length - b.transaksi_status_nama.length,
            render: ((_, {transaksi_status_nama}) => {
                switch (transaksi_status_nama) {
                    case "Lunas":
                        return (
                            <Badge 
                                count={transaksi_status_nama}
                                style={{ backgroundColor: '#52c41a' }}
                            />
                        );
                    case "Belum Ada Tagihan":
                        return (
                            <div className="badge-container" style={{ backgroundColor: '#FF9F29' }}>
                                <Text style={{ fontSize: "12px", color: 'white' }}>Menunggu Tagihan</Text>
                            </div>
                        );
                    case "Belum Bayar":
                        return (
                            <Badge 
                                count={transaksi_status_nama}
                                style={{ backgroundColor: '#FF4949' }}
                            />
                        );
                    default:
                        return null;
                }
            })
        }
    ];

    return (
        <Table 
            rowKey={(data) => data.kewajiban_id}
            rowSelection={{
                type: "checkbox",
                ...rowSelection,
            }}
            style={{ width: "100%" }} 
            columns={tableColumns} 
            dataSource={tableData}
        />
    );
};