export * from "./SplashScreen/SplashScreen";
export * from "./Dashboard/Dashboard";
export * from "./AccountRegistration/AccountRegistration";

// export * from "./AccountRegistration/R_AdminPlatform";
// export * from "./AccountRegistration/R_AdminPondok";
// export * from "./AccountRegistration/R_AdminSubPondok";
// export * from "./AccountRegistration/R_AdminLembaga";
// export * from "./AccountRegistration/R_AdminLembagaLain";

export * from "./Student/Student";

export * from "./Setting/Setting";
export * from "./Periode/Periode";
export * from "./Komponen/Komponen";
export * from "./Kewajiban/Kewajiban";