import React, { useState } from "react";
import { 
    Space,
    Table,
    Button,
    Typography,
    Popconfirm,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { ComponentModalDetail } from "./ComponentModalDetail";
import { ComponentModalEdit } from "./ComponentModalEdit";

import { deleteSantri } from "../../_services";

const { Text } = Typography;

export const ComponentTable = ({tableData, setIsSuccessHitAPI}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDataRow, setSelectedDataRow] = useState(undefined);
    const [isModalDetailOpen, setModalDetailOpen] = useState(false);
    const [isModalEditOpen, setModalEditOpen] = useState(false);
    
    const tableColumns = [
        {
            title: "NIS",
            dataIndex: "nis",
            showSorterTooltip: false,
            sorter: ((a, b) => {
                return a.nis - b.nis;
            }),
        },
        {
            title: "Nama Santri",
            dataIndex: "nama",
            showSorterTooltip: false,
            defaultSortOrder: 'ascend',
            sorter: ((a, b) => {
                const min = a.nama !== null ? a.nama?.toLowerCase() : "z";
                const max = b.nama !== null ? b.nama?.toLowerCase() : "z";
                if (min < max) {
                    return -1;
                }
                if (min > max) {
                    return 1;
                }
                return 0;
            }),
        },
        {
            title: "Kelas",
            dataIndex: "kelas",
            showSorterTooltip: false,
            sorter: ((a, b) => {
                const min = a.kelas !== null ? a.kelas.split("")[0] : "99";
                const max = b.kelas !== null ? b.kelas.split("")[0] : "99";
                return min - max
            }),
        },
        {
            title: "Jenis Kelamin",
            dataIndex: "jenis_kelamin",
            showSorterTooltip: false,
            sorter: ((a, b) => {
                const min = a.jenis_kelamin !== null ? a.jenis_kelamin?.toLowerCase() : "z";
                const max = b.jenis_kelamin !== null ? b.jenis_kelamin?.toLowerCase() : "z";
                if (min < max) {
                    return -1;
                }
                if (min > max) {
                    return 1;
                }
                return 0;
            }),
            render: ((_, {jenis_kelamin}) => {
                switch (jenis_kelamin) {
                    case "L":
                        return (
                            <Text>Pria</Text>
                        );
                    case "P":
                        return (
                            <Text>Wanita</Text>
                        );
                    default:
                        return null;
                }
            })
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "Nomor HP",
            dataIndex: "nomor_hp"
        },
        {
            title: "Action",
            dataIndex: "key",
            render: ((_, data) => 
                <Space direction="horizontal" >
                    <Button
                        className="button-custom-info"
                        type="ghost"
                        shape="circle" 
                        icon={<EyeOutlined style={{ color: "white" }} />} 
                        onClick={() => {
                            setSelectedDataRow(data);
                            setModalDetailOpen(!isModalDetailOpen);
                        }}
                    />
                    <Button
                        className="button-custom-warning"
                        type="ghost"
                        shape="circle" 
                        icon={<EditOutlined style={{ color: "white" }} />} 
                        onClick={() => {
                            setSelectedDataRow(data);
                            setModalEditOpen(!isModalDetailOpen);
                        }}
                    />
                    {/* <Popconfirm
                        placement="topRight"
                        title={`Anda akan menghapus siswa "${data.nama}".  Apakah anda Yakin?`}
                        onConfirm={() => {
                            deleteSantri(data.nis, setIsSuccessHitAPI, setIsLoading);
                        }}
                        okText="Ya"
                        cancelText="Batal"
                    >
                        <Button
                            loading={isLoading}
                            type="danger"
                            shape="circle" 
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm> */}
                </Space>
            )
        }
    ];

    return (
        <>
            <Table 
                rowKey={(data) => data.nis !== null ? data.nis : Math.floor(Math.random() * 100000000).toString()}
                style={{ width: "100%" }} 
                columns={tableColumns} 
                dataSource={tableData}
            />

            <ComponentModalDetail 
                dataRow={selectedDataRow}
                isModalDetailOpen={isModalDetailOpen}
                setModalDetailOpen={setModalDetailOpen}
            />

            <ComponentModalEdit 
                dataRow={selectedDataRow}
                isModalEditOpen={isModalEditOpen}
                setModalEditOpen={setModalEditOpen}
                setIsSuccessHitAPI={setIsSuccessHitAPI}
            />
        </>
    );
};