import React, { useEffect, useMemo, useState } from "react";
import {  
    Button,
    Upload,
    Dropdown,
    Menu,
    Divider,
    Result
} from "antd";
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { exportToExcel, importFromExcel } from "../../../_helpers";
import { santriBulkRegistration } from "../../../_services/siswaService";

import { ComponentTable } from "../ComponentTable";
import { ComponentFilterSearch } from "../ComponentFilterSearch";
import { LembagaSelection } from "../../../_Components";

// import dummySantri from "../../../Assets/_dummyData/dummySantriData.json";

// console.log(dummySantri);

export const UpperRolePage = () => {
    const allSantri = JSON.parse(localStorage.getItem("siswaAll"));

    const [tableData, setTableData] = useState([]);
    const [importedRowsData, setImportedRowsData] = useState([]);

    const [selectedLembaga, setSelectedLembaga] = useState(undefined);
    const [selectedLembagaID, setSelectedLembagaID] = useState(undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessHitAPI, setIsSuccessHitAPI] = useState(false);
    // console.log(isSuccessHitAPI);

    const santriData = useMemo(() => {
        return allSantri.filter((data) => selectedLembaga === data.lembaga);
    }, [selectedLembaga, selectedLembagaID, isSuccessHitAPI]);

    const importFromSheet = async (e) => {
        await importFromExcel(e, setImportedRowsData)
        .then((results) => {
            santriBulkRegistration(importedRowsData, setIsLoading, setIsSuccessHitAPI);
        })
        .catch((error) => {
            console.log(error);
        })
    };
    console.log(importedRowsData);

    useEffect(() => {
        setTableData(santriData);
    }, [selectedLembaga, santriData, isSuccessHitAPI]);

    const intialExcelData = useMemo(() => {
        return [{
            lembaga: selectedLembaga
        }];
    }, [selectedLembaga]);

    const excelHeadings = useMemo(() => {
        return [[
            "lembaga",
            "nis",
            "nik",
            "nama",
            "kelas",
            "jenis_kelamin",
            "username",
            "email",
            "nomor_hp",
            "nama_orang_tua_wali",
            "nomor_hp_orang_tua_wali",
            "alamat"
        ]]
    }, []);

    const dropdownMenu = (
        <Menu 
            items={[
                {
                    key: "export",
                    label: (
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                exportToExcel(excelHeadings, intialExcelData, "santri_registration_bulk", "santri_registration_bulk.xlsx", "REGISTRASI SANTRI", `Untuk pengisian "jenis_kelamin", silahkan Isikan "P" untuk Perempuan atau "L" untuk Laki-laki`);
                            }}
                        >
                            Export Excel
                        </Button>
                    ),
                },
                {
                    key: "import",
                    label: (
                        <Upload
                            showUploadList={false}
                            onChange={(e) => {
                                // importFromSheet(e);
                                importFromExcel(e, setImportedRowsData);
                                setTimeout(() => {
                                    if (importedRowsData.length >= 1) return santriBulkRegistration(importedRowsData, setIsLoading, setIsSuccessHitAPI);
                                }, 1500);
                                // if (importedRowsData.length >= 1) return santriBulkRegistration(importedRowsData, setIsLoading, setIsSuccessHitAPI);
                            }}
                        >
                            <Button
                                type="text"
                                icon={<UploadOutlined />}
                            >
                                Import EXCEL
                            </Button>
                        </Upload>
                    ),
                }
            ]}
        />
    );

    return (
        <div className="page-container">
            <div className="AP-page-header">
                <div className="AP-page-header-left-side">
                    <Dropdown
                        disabled={selectedLembaga === undefined}
                        overlay={dropdownMenu} 
                        placement="bottomLeft" 
                        trigger="click"
                        arrow
                    >
                        <Button
                            loading={isLoading}
                            size="large"
                            type="primary"
                            icon={<PlusOutlined />}
                        >
                            Santri
                        </Button>
                    </Dropdown>
                </div>
                <div className="AP-page-header-middle-side">
                    <LembagaSelection 
                        selectedLembaga={selectedLembaga}
                        setSelectedLembaga={setSelectedLembaga}
                        selectedLembagaID={selectedLembagaID}
                        setSelectedLembagaID={setSelectedLembagaID}
                    />
                </div>
                <div className="AP-page-header-right-side">
                    <ComponentFilterSearch 
                        dataToSearch={santriData}
                        setTableData={setTableData}
                        placeholder={"Pencarian"}
                    />
                </div>
            </div>
            {
                tableData.length === 0 ?
                <Result
                    status="warning"
                    title={
                        selectedLembaga === undefined ?
                        "Belum ada data Santri, silahkan Pilih Lembaga naungan Santri"
                        :
                        selectedLembaga !== undefined && tableData.length === 0 &&
                        "Data Santri masih kosong, silahkan Registrasi Santri dengan klik tombol Tambah Santri"
                    }
                />
                :
                <>
                <Divider orientation="right" style={{ textTransform: "capitalize" }} >{selectedLembaga}</Divider>
                <div className="table-container">
                    <ComponentTable 
                        tableData={tableData}
                        setIsSuccessHitAPI={setIsSuccessHitAPI}
                    />
                </div>
                </>
            }
        </div>
    );
};