import React, { useState } from "react";
import { 
    Button, 
    Modal,
    Space,
    Select,
    Typography,
    Input
} from 'antd';

import { insertKomponen } from "../../_services";

const { Text } = Typography;

export const ModalAddKomponen = ({modalAddKomponenOpen, setModalAddKomponenOpen, unitID, setIsLoading, setIsSuccessHitAPI}) => {
    const [formData, setFormData] = useState({
        nama_komponen: undefined,
        unit_id: undefined,
    })

    const handleCloseModal = () => {
        setFormData({
            nama_komponen: undefined,
            unit_id: undefined,
        });
        setModalAddKomponenOpen(false);
    };
    // console.table(formData);

    return (
        <Modal 
            title="Tambah Komponen" 
            open={modalAddKomponenOpen} 
            onCancel={() => {
                handleCloseModal();
            }}
            footer={[
                <Button 
                    key="cancel"
                    type="ghost" 
                    onClick={() => {
                        handleCloseModal();
                    }}
                >
                    Batal
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    disabled={formData?.nama_komponen === undefined ? true : false}
                    onClick={() => {
                        insertKomponen(formData, handleCloseModal, setIsLoading, setIsSuccessHitAPI);
                    }}
                >
                    Simpan
                </Button>,
            ]}
        >
            <div className="modal-body-container">
                <Space direction="vertical" style={{ display: "flex" }}>

                    <div className="form-container">
                        <div className="form-label">
                            <Text><Text style={{ color: "red" }}>* </Text>Nama Komponen</Text>
                        </div>
                        <div className="form-input">
                            <Input
                                size="large"
                                className="registration-form-input-field"
                                placeholder={`Input Nama Komponen`}
                                value={formData?.nama_komponen}
                                onChange={(e) => {
                                    setFormData({...formData, nama_komponen: e.target.value, unit_id: unitID});
                                }}
                            />
                        </div>
                    </div>

                </Space>
            </div>
        </Modal>
    );
};