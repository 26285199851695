import React, { useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    TreeSelect
} from "antd";

const { TreeNode } = TreeSelect;
const { Option } = Select;

export const UnitSelection = () => {
    const { unit_jenis, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials")); 

    const allOrganization = JSON.parse(localStorage.getItem("allOrganization"));
    const allPondok = JSON.parse(localStorage.getItem("allPondok"));
    const allSubPondok = JSON.parse(localStorage.getItem("allSubPondok"));
    const allLembaga = JSON.parse(localStorage.getItem("allLembaga"));
    const allLembagaLain = JSON.parse(localStorage.getItem("allLembagaLain"));

    const [selectedTreeData, setSelectedTreeData] = useState(undefined);

    const selectionDataForPondok = allOrganization.filter((data) => unit_tabel_id === data.id).map((data) => data.detailSubPondok)[0];
    console.log(selectionDataForPondok);

    const handleSelect = (e) => {
        const unit = e?.split("_")[0];
        const unitID = e?.split("_")[1];

        switch (unit) {
            case "pondok":
                setSelectedTreeData(allPondok.filter((data) => Number(unitID) === data.id)[0].name);
                break;
            case "subPondok":
                setSelectedTreeData(allSubPondok.filter((data) => Number(unitID) === data.id)[0].name);
                break;
            case "lembaga":
                setSelectedTreeData(allLembaga.filter((data) => Number(unitID) === data.id)[0].name);
                break;
            case "lembagaLain":
                setSelectedTreeData(allLembagaLain.filter((data) => Number(unitID) === data.id)[0].name);
                break;
            default:
                setSelectedTreeData(undefined);
                break;
        };
    };

    switch (unit_jenis) {
        case "Platform Digitren":
            return (
                <TreeSelect
                    showSearch
                    size="large"
                    style={{
                        width: '100%',
                        textTransform: "capitalize"
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    filterTreeNode={(input, option) => option.title.toLowerCase().includes(input.toLowerCase())}
                    onChange={(e) => {
                        handleSelect(e);
                    }}
                >
                    {
                        allOrganization.map((data) => 
                        <TreeNode key={`pondok_${data.id}`} value={`pondok_${data.id}`} title={data.pondokNama} >
                            {
                                data.detailSubPondok.map((dataX) => 
                                <TreeNode key={`subPondok_${dataX.id}`} value={`subPondok_${dataX.id}`} title={dataX.subPondokNama}>
                                    {
                                        dataX.detailLembaga.map((dataY) => 
                                            <TreeNode key={`lembaga_${dataY.id}`} value={`lembaga_${dataY.id}`} title={dataY.lembagaNama} />
                                        )
                                    }
                                    {
                                        dataX.detailLembagaLain.map((dataY) => 
                                            <TreeNode key={`lembagaLain_${dataY.id}`} value={`lembagaLain_${dataY.id}`} title={dataY.lembagaLainNama} />
                                        )
                                    }
                                </TreeNode>
                                )
                            }
                        </TreeNode>
                        )
                    }
                </TreeSelect>
            );
        case "Pondok":
            return (
                <TreeSelect
                    showSearch
                    size="large"
                    style={{
                        width: '100%',
                        textTransform: "capitalize"
                    }}
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                    }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    filterTreeNode={(input, option) => option.title.toLowerCase().includes(input.toLowerCase())}
                    onChange={(e) => {
                        handleSelect(e);
                    }}
                >
                    {
                        selectionDataForPondok.map((dataX) => 
                        <TreeNode key={`subPondok_${dataX.id}`} value={`subPondok_${dataX.id}`} title={dataX.subPondokNama}>
                            {
                                dataX.detailLembaga.map((dataY) => 
                                    <TreeNode key={`lembaga_${dataY.id}`} value={`lembaga_${dataY.id}`} title={dataY.lembagaNama} />
                                )
                            }
                            {
                                dataX.detailLembagaLain.map((dataY) => 
                                    <TreeNode key={`lembagaLain_${dataY.id}`} value={`lembagaLain_${dataY.id}`} title={dataY.lembagaLainNama} />
                                )
                            }
                        </TreeNode>
                        )
                    }
                </TreeSelect>
            )   
        default:
            return null;
    }
};