import React from "react";
import { Route, Routes, Navigate, Router, Outlet } from "react-router-dom";

import { PublicRoutes } from "./PublicRoutes";
import { AuthLayout } from "../Auth/AuthLayout";

import { ProtectedRoutes } from "./ProtectedRoutes";
import { PageLayout } from "../Pages/PageLayout";
import { Setting } from "../Pages";
import { Error } from "../Pages/Error/Error";

import { publicRoutesList, protectedRoutesList } from "./RoutesRegistry";

export const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<PublicRoutes />} >
                <Route path="/" element={<AuthLayout />} >
                    {
                        publicRoutesList.map((data, index) => 
                            <Route key={index} {...data.isIndex === true ? index : null} path={data.path} element={data.element} />
                        )
                    }
                </Route>
            </Route>
            <Route path="/" element={<ProtectedRoutes />} >
                <Route path="/" element={<PageLayout />} >
                    {
                        protectedRoutesList.map((data, index) => {
                            if (data.children.length === 0) {
                                return (
                                    <Route key={index} {...data.isIndex === true ? index : null} path={data.path} element={data.element} />
                                )
                            }
                            else {
                                return (
                                    data.children.map((data, index) =>
                                        <Route key={index} path={data.path} element={data.element} />
                                    )
                                )
                            }
                        })
                    }
                    <Route path="/setting" element={<Setting />} />
                </Route>
            </Route>
            <Route path="*" element={<Error />} />
        </Routes>
    );
};