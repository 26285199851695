import React, { useEffect, useMemo, useState } from "react";
import {  
    Button,
    Upload,
    Dropdown,
    Menu,
    Divider,
    Result
} from "antd";
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { ComponentFilterSearch } from "../ComponentFilterSearch";
import { ComponentTable } from "../ComponentTable";
import { ModalPeriode } from "../ModalPeriode";

export const LowerRolePage = () => {
    const { unit_nama, unit_id } = JSON.parse(localStorage.getItem("userCredentials"));
    const userAndRole = JSON.parse(localStorage.getItem("userAndRole"));
    const periode = JSON.parse(localStorage.getItem("periode"));
    const periodeJenis = JSON.parse(localStorage.getItem("periodeJenis"));

    const [tableData, setTableData] = useState([]);
    const [dataPeriode, setDataPeriode] = useState([]);
    const [dataPeriodeJenis, setDataPeriodeJenis] = useState(periodeJenis);
    const [modalAddPeriodeOpen, setModalAddPeriodeOpen] = useState(false);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessHitAPI, setIsSuccessHitAPI] = useState(false);

    // const getUnitID = useMemo(() => {
    //     return userAndRole.filter((data) => data.vUnitNama === unit_nama)[0]?.vUnitID;
    // }, [unit_nama, isSuccessHitAPI]);
    
    useEffect(() => {
        setTableData(periode.filter((data) => data.unit_id === unit_id));
        setDataPeriode(periode.filter((data) => data.unit_id === unit_id));
    }, [unit_id, isSuccessHitAPI]);

    // console.log(getUnitID);

    return (
        <div className="periode-page-container">
            <div className="lembaga-page-header">
                <div className="lembaga-left-section">
                    <Button
                        loading={isLoading}
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setModalAddPeriodeOpen(!modalAddPeriodeOpen);
                        }}
                    >
                        Periode
                    </Button>
                </div>
                <div className="lembaga-right-section">
                    <ComponentFilterSearch 
                        dataPeriode={dataPeriode}
                        dataToSearch={tableData}
                        setTableData={setTableData}
                        placeholder={"Pencarian"}
                    />
                </div>
            </div>
            <Divider orientation="right" style={{ textTransform: "capitalize" }} >{unit_nama}</Divider>
            {
                tableData.length === 0 ?
                <Result
                    status="warning"
                    title="Data Periode masih kosong, silahkan Buat Periode dengan klik tombol Tambah Periode"
                />
                : 
                <div className="table-container">
                    <ComponentTable 
                        tableData={tableData}
                    />
                </div>
            }

            <ModalPeriode 
                modalAddPeriodeOpen={modalAddPeriodeOpen}
                setModalAddPeriodeOpen={setModalAddPeriodeOpen}
                dataPeriodeJenis={dataPeriodeJenis}
                setDataPeriode={setTableData}
                getUnitID={unit_id}
                setIsLoading={setIsLoading}
                setIsSuccessHitAPI={setIsSuccessHitAPI}
            />
        </div>
    );
};