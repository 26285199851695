import axios from "axios";
import { removeEmptyObjectKeys, openNotification } from "../_helpers";

export const getAllSiswa = async () => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/get/siswa`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        localStorage.setItem("siswaAll", JSON.stringify(response.data.data));
        // console.log(dataKewajiban.map((data) => data.transaksi_status_nama));
    } catch (error) {
        console.log(error.response);
    }
};

export const santriBulkRegistration = async (payload, setIsLoading, setIsSuccessHitAPI) => {
    setIsLoading(true);
    setIsSuccessHitAPI(false);

    const { token } = JSON.parse(localStorage.getItem("userCredentials"));
    // console.log(payload);

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/regist/siswa`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllSiswa();
        setIsLoading(false);
        setIsSuccessHitAPI(true);
        openNotification("success", "Sukses", "Registrasi Santri Berhasil!");
        // console.log(dataKewajiban.map((data) => data.transaksi_status_nama));
    } catch (error) {
        setIsLoading(false);
        setIsSuccessHitAPI(false);
        openNotification("error", "Gagal", "Registrasi Santri gagal, silahkan coba lagi!");
        console.log(error.response);
    }
};

export const editSantri = async (payload, setIsSuccessHitAPI, setModalEditOpen, setIsLoading) => {
    setIsSuccessHitAPI(false);
    setIsLoading(true);

    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_PATH}/update/siswa/profile`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllSiswa();
        setIsSuccessHitAPI(true);
        setIsLoading(false);
        setModalEditOpen(false);
        openNotification("success", "Sukses", "Edit Santri Berhasil!");
    } catch (error) {
        setIsSuccessHitAPI(false);
        setIsLoading(false);
        openNotification("error", "Gagal", "Edit Santri gagal, silahkan coba lagi!");
        console.log(error.response);
    }
};

export const deleteSantri = async (santriNIS, setIsSuccessHitAPI, setIsLoading) => {
    setIsSuccessHitAPI(false);
    setIsLoading(true);

    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_PATH}/delete/siswa/${santriNIS}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        await getAllSiswa();
        setIsSuccessHitAPI(true);
        setIsLoading(false);
        openNotification("success", "Sukses", "Hapus Santri Berhasil!");
    } catch (error) {
        openNotification("error", "Gagal", "Hapus Santri gagal, silahkan coba lagi!");
        console.log(error.response);
    }
};