import axios from "axios";

export const getUserAndRole = async () => {
    // console.log("GET USER AND ROLE");
    // setIsLoading(true);
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/get/userAndRole`);
        localStorage.setItem("userAndRole", JSON.stringify(response.data.data));
        // setRole(response.data.data);
        // setIsLoading(false);
        // console.log(response.data.data);
    } catch (error) {
        // setIsLoading(false);
        console.log(error.response.message);
    }
};

export const getUnitJenis = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/get/unitJenis`);
        localStorage.setItem("unitJenis", JSON.stringify(response.data.data));
        // console.log(response.data.data);
    } catch (error) {
        console.log(error.response);
    }
};

export const getGroup = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/get/group`);
        const groupData = response.data.data.filter((data) => !["Admin Platform Digitren", "Tes New Role"].includes(data.groupNama));
        localStorage.setItem("group", JSON.stringify(groupData));
        // console.log("GROUP: ", response.data.data);
    } catch (error) {
        console.log(error.response);
    }
};

export const getUserJenis = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/get/userJenis`);
        localStorage.setItem("userJenis", JSON.stringify(response.data.data.slice(0, -4)));
        // console.log(response.data.data);
    } catch (error) {
        console.log(error.response);
    }
};

export const getAllOrganization = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/get/organization`);
        localStorage.setItem("allOrganization", JSON.stringify(response.data.data));

        const getPondok = response.data.data.map((data) => {
            return {
                id: data.id,
                name: data.pondokNama
            }
        });
        localStorage.setItem("allPondok", JSON.stringify(getPondok));

        let listAllSubPondok = [];
        const getSubPondok = response.data.data.filter((data) => data.detailSubPondok.length >= 1).map((data) => data.detailSubPondok);
        getSubPondok.forEach(data => {
            data.forEach(dataX => {
                listAllSubPondok.push({
                    id: dataX.id,
                    pondokID: dataX.pondokID,
                    name: dataX.subPondokNama,
                })
            })
        });
        localStorage.setItem("allSubPondok", JSON.stringify(listAllSubPondok));

        let listAllLembaga = [];
        const getLembaga = response.data.data.filter((data) => data.detailSubPondok.length >= 1).map((data) => data.detailSubPondok.map((data) => data.detailLembaga));
        getLembaga.forEach(data => {
            data.forEach(dataX => {
                dataX.forEach(dataY => {
                    listAllLembaga.push({
                        id: dataY.id,
                        subPondokID: dataY.subPondokID,
                        name: dataY.lembagaNama,
                    })
                })
            })
        });
        localStorage.setItem("allLembaga", JSON.stringify(listAllLembaga));

        let listAllLembagaLain = [];
        const getLembagaLain = response.data.data.filter((data) => data.detailSubPondok.length >= 1).map((data) => data.detailSubPondok.map((data) => data.detailLembagaLain));
        getLembagaLain.forEach(data => {
            data.forEach(dataX => {
                dataX.forEach(dataY => {
                    listAllLembagaLain.push({
                        id: dataY.id,
                        subPondokID: dataY.subPondokID,
                        name: dataY.lembagaLainNama,
                    })
                })
            })
        });
        localStorage.setItem("allLembagaLain", JSON.stringify(listAllLembagaLain));
    } catch (error) {
        console.log(error.response);
    }
};