import React, { useMemo } from "react";
import {
    Select,
    Typography,
} from "antd";

const { Text } = Typography;
const { Option } = Select;

export const LembagaSelection = ({selectedLembaga, setSelectedLembaga, selectedLembagaID, setSelectedLembagaID}) => {
    const { unit_jenis, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials")); 

    const allOrganization = JSON.parse(localStorage.getItem("allOrganization"));
    const allLembaga = JSON.parse(localStorage.getItem("allLembaga"));
    const allLembagaLain = JSON.parse(localStorage.getItem("allLembagaLain"));

    const selection = useMemo(() => {
        switch (unit_jenis) {
            case "Platform Digitren":
                return [
                    ...allLembaga,
                    ...allLembagaLain,
                ];
            case "Pondok":
                let listID = [];
                const pondokTempData = allOrganization.filter((data) => unit_tabel_id === data.id).map((data) => data.detailSubPondok.map((dataX) => dataX.id));

                pondokTempData.forEach(items => {
                    items.forEach(itemsX => {
                        listID.push(itemsX);
                    })
                });
                return [
                    ...allLembaga.filter((data) => listID.includes(data.subPondokID)),
                    ...allLembagaLain.filter((data) => listID.includes(data.subPondokID)),
                ];
            case "Sub Pondok":
                return [
                    ...allLembaga.filter((data) => unit_tabel_id === data.subPondokID),
                    ...allLembagaLain.filter((data) => unit_tabel_id === data.subPondokID),
                ];
            default:
                return null;
        };
    }, []);

    return (
        <Select
            showSearch
            size="large"
            className="login-select-field registration-select-filed"
            placeholder={"Pilih Lembaga"}
            optionFilterProp="children"
            value={selectedLembaga}
            style={{ textTransform: "capitalize" }}
            onChange={(e) => {
                const lembagaName = e?.split("_")[0];
                const lembagaID = e?.split("_")[1];
                setSelectedLembaga(lembagaName);
                setSelectedLembagaID(Number(lembagaID));
            }}
            filterOption={(input, option) => option.value.toLowerCase().includes(input.toLowerCase())}
        >
            {
                selection?.map((items, index) => 
                    <Option key={index} value={`${items?.name}_${items?.id}`}>
                        <Text style={{ textTransform: "capitalize" }}>{items?.name}</Text>
                    </Option>
                )
            }
        </Select>
    );
};