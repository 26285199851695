import React, { useEffect, useState } from "react";
import { 
    Alert,
    Button,
    Divider,
    Input,
    Radio,
    Typography,
    Steps,
    Space
} from "antd";

import { FormInstitutionRegistration } from "./FormInstitutionRegistration";
import { FormAccountRegistration } from "./FormAccountRegistration";
import { rules } from "./rules";

import { UserJenisSelection, GroupSelection, UnitJenisSelection } from "../../../_Components";

import { registrationServiceV2 } from "../../../_services";

const { Text } = Typography;
const { Step } = Steps;

const AdminLembaga = () => {
    const { unit_nama, unit_tabel_id } = JSON.parse(localStorage.getItem("userCredentials"));

    const [formData, setFormData] = useState({
        selectedInstitutionName: undefined,
        institutionName: undefined,
        institutionID: "",
        institutionPhoneNumber: "",
        institutionAddress: "",
        personalName: "", 
        studentIdNumber: "",
        studentName: undefined,
        studentUserID: "",
        employeeIdNumber: "",
        phoneNumber: "",
        userName: "",
        userEmail: "",
        userPassword: "",
        userJenisName: undefined,
        userJenisID: undefined,
        groupName: undefined,
        groupID: undefined,
        groupSelectionData: [],
        unitJenisName: undefined,
        unitJenisID: undefined,
        accountType: undefined,
        pondokID: "",
        subPondokID: "",
        lembagaID: "",
        lembagaLainID: "",
    });

    const [currentStep, setCurrentStep] = useState(0);
    const [stepButtonDisabled, setStepButtonDisabled] = useState(true);
    const [alertType, setAlertType] = useState("warning");
    const [alertMessage, setAlertMessage] = useState(undefined);
    const [alertDescription, setAlertDescription] = useState(undefined);

    useEffect(() => {
        rules(
            formData, 
            setAlertType,
            setAlertMessage, 
            setAlertDescription, 
            setStepButtonDisabled, 
            currentStep,
        );
    }, [formData, currentStep]);

    const next = () => {
        setCurrentStep(currentStep + 1);
        if ((formData.userJenisID !== undefined && formData.groupID === "1" && formData.unitJenisID === "3" && formData.accountType === "addAccount") || (formData.userJenisID !== undefined && formData.groupID !== undefined && formData.unitJenisID === "3" && formData.accountType === undefined)) {
            setFormData({...formData, institutionName: unit_nama, subPondokID: "", lembagaID: unit_tabel_id, lembagaLainID: ""});
        }
        else {
            setFormData({...formData, subPondokID: "", lembagaID: "", lembagaLainID: ""});
        }
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    // console.log(formData);

    const steps = [
        {
            title: 'Role User',
            content: (() => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className="form-select-container">
                            <div className="form-select-label">
                                <Text><Text style={{ color: "red" }}>*</Text> Pilih User Jenis</Text>
                            </div>
                            <div className="form-select">
                                <UserJenisSelection 
                                    showSearch={false}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className="form-select-container">
                            <div className="form-select-label">
                                <Text><Text style={{ color: "red" }}>*</Text> Pilih Group</Text>
                            </div>
                            <div className="form-select">
                                <GroupSelection 
                                    showSearch={false}
                                    disable={formData.groupSelectionData?.length > 1 ? false : true}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className="form-select-container">
                            <div className="form-select-label">
                                <Text><Text style={{ color: "red" }}>*</Text> Pilih Unit Jenis</Text>
                            </div>
                            <div className="form-select">
                                <UnitJenisSelection 
                                    showSearch={false}
                                    disable={true}
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                        <div className="form-select-container">
                            <div className="form-select-label">
                                <Text><Text style={{ color: "red" }}>*</Text> Pilih Tipe Akun</Text>
                            </div>
                            <div className="form-select">
                                <Radio.Group  
                                    size="large"
                                    buttonStyle="solid"
                                    disabled={true}
                                    value={formData.accountType}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            selectedInstitutionName: undefined,
                                            institutionName: undefined,
                                            institutionID: "",
                                            institutionPhoneNumber: "",
                                            institutionAddress: "",
                                            personalName: "", 
                                            studentIdNumber: "",
                                            studentName: undefined,
                                            studentUserID: "",
                                            employeeIdNumber: "",
                                            userName: "",
                                            userEmail: "",
                                            userPassword: "",
                                            accountType: e.target.value
                                        });
                                    }} 
                                >
                                    <Radio.Button value="createNew">Buat Baru</Radio.Button>
                                    <Radio.Button value="addAccount">Tambah Akun</Radio.Button>
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                );
            })(),
        },
        {
            title: 'Institusi',
            content: (() => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <FormInstitutionRegistration 
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                );
            })(),
        },
        {
            title: `Akun ${formData.userJenisName === undefined ? "" : formData.userJenisName}`,
            content: (() => {
                return (
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <FormAccountRegistration 
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </div>
                );
            })(),
        },
    ];

    return (
        <div className="main-page-container">
            <Space direction="vertical" size="middle">
                <Alert 
                    showIcon
                    type={alertType}
                    message={alertMessage}
                    description={alertDescription}
                />
                <div className="segment-container">
                    <Steps current={currentStep}>
                        {
                            steps.map((item) => (
                                <Step key={item.title} title={item.title}/>
                            ))
                        }
                    </Steps>
                    <div className="steps-content">
                        {steps[currentStep].content}
                    </div>
                    <div className="steps-action">
                        {
                            currentStep > 0 && (
                                <Button
                                    size="large"
                                    style={{
                                    margin: '0 8px',
                                    }}
                                    onClick={() => prev()}
                                >
                                    Sebelumnya
                                </Button>
                            )
                        }
                        {
                            currentStep < steps.length - 1 && (
                                <Button 
                                    size="large"
                                    type="primary" 
                                    disabled={stepButtonDisabled}
                                    onClick={() => {
                                        next()
                                    }}
                                >
                                    Berikutnya
                                </Button>
                            )
                        }
                        {
                            currentStep === steps.length - 1 && (
                                <Button 
                                    size="large"
                                    type="primary" 
                                    disabled={stepButtonDisabled}
                                    onClick={() => {
                                        registrationServiceV2(formData, setFormData, setCurrentStep);
                                    }}
                                >
                                    Simpan
                                </Button>
                            )
                        }
                    </div>
                </div>
            </Space>
        </div>
    );
};

export default AdminLembaga;