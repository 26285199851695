import React, { useEffect, useMemo, useState } from "react";
import { 
    Alert,
    Button,
    Divider,
    Input,
    Radio,
    Typography,
    Select
} from "antd";

import { 
    PondokSelection, 
    SubPondokSelection, 
    LembagaSelectionForRegistration ,
    LembagaLainSelectionForRegistration,
} from "../../../_Components";

const { Text } = Typography;
const { Option } = Select;

export const FormAccountRegistration = ({formData, setFormData}) => {
    const userAndRole = JSON.parse(localStorage.getItem("userAndRole"));
    const siswa = JSON.parse(localStorage.getItem("siswaAll"));

    const getUnitID = useMemo(() => {
        return userAndRole.filter((data) => data.vUnitNama === formData.institutionName)[0]?.vUnitID;
    }, [userAndRole]);

    const getSiswa = useMemo(() => {
        return siswa.filter((data) => data.unit_id === getUnitID);
    }, [getUnitID]);
    // console.log(getSiswa);

    const renderLabelName = useMemo(() => {
        switch (true) {
            case formData.userJenisID === "2" || formData.userJenisID === "3":
                return "Siswa";
            case formData.userJenisID === "1":
                return "Pengajar";
            case formData.userJenisID === "4":
                return "Orang Tua / Wali";
            default:
                return "Penanggung Jawab";
        };
    }, [formData]);

    return (
        <>
        {
            getSiswa.length > 0 && formData.userJenisID === "4" && formData.groupID === "5" &&
            <div className="form-select-container">
                <div className="form-select-label">
                    <Text><Text style={{ color: "red" }}>* </Text>Pilih Nama Siswa</Text>
                </div>
                <div className="form-select">
                    <Select
                        showSearch
                        size="large"
                        placeholder="Pilih Siswa"
                        className="registration-form-input-field"
                        optionFilterProp="children"
                        value={formData.studentName}
                        onChange={(e) => {
                            const studentName = e?.split("_")[0];
                            const studentUserID = e?.split("_")[1];
                            setFormData({...formData, studentName, studentUserID});
                        }}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {
                            getSiswa?.map((items, index) => 
                                <Option key={index} value={`${items.nama}_${items.user_id}`}>{items.nama}</Option>
                            )
                        }
                    </Select>
                </div>
            </div>
        }
        <div className="form-select-container">
            <div className="form-select-label">
                <Text><Text style={{ color: "red" }}>* </Text>Nama {renderLabelName}</Text>
            </div>
            <div className="form-select">
                <Input
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Nama Lengkap`}
                    value={formData?.personalName}
                    // disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, personalName: e.target.value});
                    }}
                />
            </div>
        </div>
        {
            formData.userJenisID === "2" ?
            <div className="form-select-container">
                <div className="form-select-label">
                    <Text><Text style={{ color: "red" }}>* </Text>Nomor Induk {renderLabelName}</Text>
                </div>
                <div className="form-select">
                    <Input
                        size="large"
                        className="registration-form-input-field"
                        placeholder={`Input Nomor Induk ${renderLabelName}`}
                        value={formData.studentIdNumber}
                        // disabled={setDisabledFormInput}
                        onChange={(e) => {
                            setFormData({...formData, studentIdNumber: e.target.value});
                        }}
                    />
                </div>
            </div>
            :
            formData.userJenisID === "3" ?
            <div className="form-select-container">
                <div className="form-select-label">
                    <Text><Text style={{ color: "red" }}>* </Text>Nomor Induk {renderLabelName}</Text>
                </div>
                <div className="form-select">
                    <Input
                        size="large"
                        className="registration-form-input-field"
                        placeholder={`Input Nomor Induk ${renderLabelName}`}
                        value={formData.studentIdNumber}
                        // disabled={setDisabledFormInput}
                        onChange={(e) => {
                            setFormData({...formData, studentIdNumber: e.target.value});
                        }}
                    />
                </div>
            </div>
            :
            formData?.userJenisID === "1" ?
            <div className="form-select-container">
                <div className="form-select-label">
                    <Text>Nomor Induk {renderLabelName}</Text>
                </div>
                <div className="form-select">
                    <Input
                        size="large"
                        className="registration-form-input-field"
                        placeholder={`Input Nomor Induk Pegawai`}
                        value={formData.employeeIdNumber}
                        // disabled={setDisabledFormInput}
                        onChange={(e) => {
                            setFormData({...formData, employeeIdNumber: e.target.value});
                        }}
                    />
                </div>
            </div>
            :
            null
        }
        {/* {
            formData?.userJenisID === "4" && 
            <div className="form-select-container">
                <div className="form-select-label">
                    <Text><Text style={{ color: "red" }}>* </Text>Nomor Handphone</Text>
                </div>
                <div className="form-select">
                    <Input
                        size="large"
                        className="registration-form-input-field"
                        placeholder={`Input Nomor Handphone`}
                        value={formData.phoneNumber}
                        // disabled={setDisabledFormInput}
                        onChange={(e) => {
                            setFormData({...formData, phoneNumber: e.target.value});
                        }}
                    />
                </div>
            </div>
        } */}
        <div className="form-select-container">
            <div className="form-select-label">
                <Text><Text style={{ color: "red" }}>* </Text>Username</Text>
            </div>
            <div className="form-select">
                <Input
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Username`}
                    value={formData?.userName}
                    // disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, userName: e.target.value});
                    }}
                />
            </div>
        </div>
        <div className="form-select-container">
            <div className="form-select-label">
                <Text><Text style={{ color: "red" }}>* </Text>User Email</Text>
            </div>
            <div className="form-select">
                <Input
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Email Aktif`}
                    value={formData?.userEmail}
                    // disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, userEmail: e.target.value});
                    }}
                />
            </div>
        </div>
        <div className="form-select-container">
            <div className="form-select-label">
                <Text><Text style={{ color: "red" }}>* </Text>Password</Text>
            </div>
            <div className="form-select">
                <Input.Password
                    size="large"
                    className="registration-form-input-field"
                    placeholder={`Input Password`}
                    value={formData?.userPassword}
                    // disabled={setDisabledFormInput}
                    onChange={(e) => {
                        setFormData({...formData, userPassword: e.target.value});
                    }}
                />
            </div>
        </div>
        </>
    );
};