import React, { useEffect, useState } from 'react';
import { SplashScreen } from './Pages';

import { MainRoutes } from './Routes/MainRoutes';

import { getAll } from './_services';

import './App.less';

const App = () => {
  const [isCounting, setIsCounting] = useState(true);
  const [number, setNumber] = useState(0);
  // console.log(number);

  // useEffect(() => {
  //   // getUserAndRole();
  //   let counter;
  //   if (isCounting) {
  //     counter = setInterval(() => {
  //       setNumber((previousState) => previousState + 1);
  //     }, 50);
  //   } else {
  //     clearInterval(counter);
  //   }

  //   return () => {
  //     clearInterval(counter);
  //   }
  // }, [isCounting]);

  // useEffect(() => {
  //   if (number === 100 && isCounting) {
  //     setIsCounting(false);
  //     setNumber(0);
  //   }
  // }, [isCounting, number]);

  useEffect(() => {
    // getAll();
  }, []);

  return (
    <>
    {/* <SplashScreen /> */}
    <MainRoutes />
    </>
  );
};

export default App;