import React, { useEffect, useMemo, useState } from "react";
import { 
    Input,
    Space,
    Table,
    Image,
    Select, 
    Button,
    DatePicker,
    Typography,
    Upload,
    Dropdown,
    Menu,
    Tooltip,
    Alert,
    Divider,
    Result,
    Card,
    Tag,
    Radio
} from "antd";
import { currencyFormatter } from "../../_helpers";

const { Text } = Typography;

export const ComponentCard = ({tableData}) => {
    console.log(tableData);

    const RenderStatus = ({dataStatus}) => {
        switch (dataStatus) {
            case "Lunas":
                return (
                    <Tag color="#52c41a">{dataStatus}</Tag>
                );
            case "Belum Ada Tagihan":
                return (
                    <Tag color="#FF9F29">Menunggu Tagihan</Tag>
                );
            case "Belum Bayar":
                return (
                    <Tag color="#FF4949">{dataStatus}</Tag>
                );
            default:
                return null;
        }
    };

    return (
        <Space direction="horizontal" wrap >
            {
                tableData?.map((data, index) => 
                <Card
                    hoverable
                    key={index}
                    title={(() => {
                        return (
                            <Space direction="vertical">
                                <Text>{data?.user_nama}</Text>
                                <Text style={{ fontWeight: "normal" }} >{data.user_no_induk}</Text>
                            </Space>
                        )
                    })()}
                    extra={
                        <Button
                            shape="circle"
                            type="primary"
                        >
                            {data.user_kelas}
                        </Button>
                    }
                    style={{
                        width: 300,
                    }}
                >
                    <Space direction="vertical" style={{ display: "flex" }} >
                        <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Text style={{ fontWeight: "bold" }}>{data.periode_nama}</Text>
                            <Tag color="volcano">{data.komponen_nama}</Tag>
                        </Space>
                        <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Text style={{  }}>Kewajiban</Text>
                            <Text style={{ fontWeight: "bold" }}>Rp. {currencyFormatter(Number(data.kewajiban_nominal))}</Text>
                        </Space>
                        <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Text style={{  }}>Tagihan</Text>
                            <Text style={{ fontWeight: "bold" }}>{data.sum_d === null ? "-" : "Rp. " + currencyFormatter(Number(data.sum_d))}</Text>
                        </Space>
                        <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
                            <Text style={{  }}>Bayar</Text>
                            <Text style={{ fontWeight: "bold" }}>{data.sum_k === null ? "-" : "Rp. " + currencyFormatter(Number(data.sum_k))}</Text>
                        </Space>
                        <Divider orientation="right" style={{ textTransform: "capitalize" }} >Status</Divider>
                        <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
                            {/* <Tag color="#FF9F29">{data.transaksi_status_nama}</Tag> */}
                            <RenderStatus 
                                dataStatus={data.transaksi_status_nama}
                            />
                            <Radio />
                        </Space>
                    </Space>
                </Card>
                )
            }
        </Space>
    );
};