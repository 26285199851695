import React from "react";
import { Link, Navigate } from "react-router-dom";

//#region web icon
import { 
    LaptopOutlined,
    HomeOutlined,
    UserAddOutlined,
    CalendarOutlined,
    SolutionOutlined,
    ReconciliationOutlined,
    TagsOutlined
} from '@ant-design/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faGamepad } from "@fortawesome/free-solid-svg-icons";
//#endregion

//#region public routes page
import { Login } from "../Auth/Login";
// import { Register } from "../pages/auth/Register";
// import { ForgotPassword } from "../pages/auth/ForgotPassword";
//#endregion

//#region protected routes page
import { 
    Dashboard,
    AccountRegistration,
    Student,
    Periode,
    Komponen,
    Kewajiban
} from "../Pages";
//#endregion

//#region public route list
export const publicRoutesList = [
    {
        key: "login",
        path: "/login",
        index: true,
        element: <Login />
    },
    {
        key: "/",
        path: "/",
        isIndex: false,
        element: <Navigate to={"/login"} replace/>
    }
]
//#endregion

//#region protected route list
export const protectedRoutesList = [
    {
        key: "dashboard",
        label: <Link to="/dashboard" >Dashboard</Link>,
        path: "/dashboard",
        children: [],
        index: true,
        element: <Dashboard />,
        icon: <HomeOutlined />
    },
    {
        key: "company_registration",
        label: <Link to="/registrasi" >Registrasi</Link>,
        path: "/registrasi",
        children: [],
        index: false,
        element: <AccountRegistration />,
        icon: <SolutionOutlined />
    },
    {
        key: "student",
        label: <Link to="/santri" >Santri</Link>,
        path: "/santri",
        children: [],
        index: false,
        element: <Student />,
        icon: <UserAddOutlined />
    },
    {
        key: "periode",
        label: <Link to="/periode" >Periode</Link>,
        path: "/periode",
        children: [],
        index: false,
        element: <Periode />,
        icon: <CalendarOutlined />
    },
    {
        key: "komponen",
        label: <Link to="/komponen" >Komponen</Link>,
        path: "/komponen",
        children: [],
        index: false,
        element: <Komponen />,
        icon: <TagsOutlined />
    },
    {
        key: "kewajiban",
        label: <Link to="/kewajiban" >Kewajiban</Link>,
        path: "/kewajiban",
        children: [],
        index: false,
        element: <Kewajiban />,
        icon: <ReconciliationOutlined />
    },
    // {
    //     key: "page_one",
    //     label: "Page One",
    //     path: "/pageOne",
    //     children: [
    //         {
    //             key: "child_one",
    //             label: <Link to="/page_one/child_one" >Child One</Link>,
    //             path: "/page_one/child_one",
    //             element: <P1ChildOne />,
    //             icon: null,
    //             children: null
    //         },
    //         {
    //             key: "child_two",
    //             label: <Link to="/page_one/child_two" >Child Two</Link>,
    //             path: "/page_one/child_two",
    //             element: <P1ChildTwo />,
    //             icon: null,
    //             children: null
    //         },
    //         {
    //             key: "child_three",
    //             label: <Link to="/page_one/child_three" >Child Three</Link>,
    //             path: "/page_one/child_three",
    //             element: <P1ChildThree />,
    //             icon: null,
    //             children: null
    //         }
    //     ],
    //     isIndex: false,
    //     element: null,
    //     icon: <LaptopOutlined />
    // },
    // {
    //     key: "page_two",
    //     label: "Page Two",
    //     path: "/pageTwo",
    //     children: [
    //         {
    //             key: "child_one",
    //             label: <Link to="/page_two/child_one" >Child One</Link>,
    //             path: "/page_two/child_one",
    //             element: <P2ChildOne />,
    //             icon: null,
    //             children: null
    //         },
    //         {
    //             key: "child_two",
    //             label: <Link to="/page_two/child_two" >Child Two</Link>,
    //             path: "/page_two/child_two",
    //             element: <P2ChildTwo />,
    //             icon: null,
    //             children: null
    //         },
    //         {
    //             key: "child_three",
    //             label: <Link to="/page_two/child_three" >Child Three</Link>,
    //             path: "/page_two/child_three",
    //             element: <P2ChildThree />,
    //             icon: null,
    //             children: null
    //         }
    //     ],
    //     isIndex: false,
    //     element: null,
    //     icon: <LaptopOutlined />
    // },
]
//#endregion

/* 
## Sidebar items objects order:
{ label, key, icon, children }

## Sidebar items without children:
        label                   key         icon         children
<Link to="/home" >Home</Link>, "home", <HomeOutlined />, null

## Sidebar items with children:
"Page Two", ---> label
"page_two", ---> key
<LaptopOutlined />, ---> icon
[
                label                                      key     icon  children
    <Link to="/page_two/child_one" >Child One</Link>, "child_one", null, null, ---> children
    <Link to="/page_two/child_two" >Child Two</Link>, "child_two", null, null
    <Link to="/page_two/child_three" >Child Three</Link>, "child_three", null, null
]
*/