import React, { useEffect, useMemo, useState } from "react";
import { 
    Menu,
    Upload,
    Button,
    Divider,
    Dropdown,
} from "antd";
import { DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";

import { exportToExcel, importFromExcel } from "../../../_helpers";
import { santriBulkRegistration } from "../../../_services/siswaService";

import { ComponentTable } from "../ComponentTable";
import { ComponentFilterSearch } from "../ComponentFilterSearch";

// import dummySantri from "../../../Assets/_dummyData/dummySantriData.json";

export const LowerRolePage = () => {
    const { unit_nama } = JSON.parse(localStorage.getItem("userCredentials"));
    const allSantri = JSON.parse(localStorage.getItem("siswaAll"));

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessBulkRegistration, setIsSuccessBulkRegistration] = useState(false);
    const [isSuccessHitAPI, setIsSuccessHitAPI] = useState(false);

    const santriData = useMemo(() => {
        return allSantri.filter((data) => unit_nama.includes(data.lembaga));
    }, [isSuccessBulkRegistration, isSuccessHitAPI]);

    const [tableData, setTableData] = useState(santriData);
    const [importedRowsData, setImportedRowsData] = useState([]);
    // console.log(importedRowsData);

    useEffect(() => {
        setTableData(santriData);
    }, [santriData, isSuccessHitAPI, isSuccessBulkRegistration]);

    const intialExcelData = useMemo(() => {
        return [{
            lembaga: unit_nama
        }];
    }, []);

    const excelHeadings = useMemo(() => {
        return [[
            "lembaga",
            "nis",
            "nik",
            "nama",
            "kelas",
            "jenis_kelamin",
            "username",
            "email",
            "nomor_hp",
            "nama_orang_tua_wali",
            "nomor_hp_orang_tua_wali",
            "alamat"
        ]]
    }, []);
    // console.log(importedRowsData);

    const dropdownMenu = (
        <Menu 
            items={[
                {
                    key: "export",
                    label: (
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                exportToExcel(excelHeadings, intialExcelData, "santri_registration_bulk", "santri_registration_bulk.xlsx", "REGISTRASI SANTRI", `Untuk pengisian "jenis_kelamin", silahkan Isikan "P" untuk Perempuan atau "L" untuk Laki-laki`);
                            }}
                        >
                            Export Excel
                        </Button>
                    ),
                },
                {
                    key: "import",
                    label: (
                        <Upload
                            showUploadList={false}
                            onChange={(e) => {
                                importFromExcel(e, setImportedRowsData);
                                setTimeout(() => {
                                    if (importedRowsData.length >= 1) return santriBulkRegistration(importedRowsData, setIsLoading, setIsSuccessBulkRegistration);
                                }, 1500);
                            }}
                        >
                            <Button
                                type="text"
                                icon={<UploadOutlined />}
                            >
                                Import EXCEL
                            </Button>
                        </Upload>
                    ),
                }
            ]}
        />
    );

    return (
        <div className="page-container">
            <div className="lembaga-page-header">
                <div className="lembaga-left-section">
                    <Dropdown
                        overlay={dropdownMenu} 
                        placement="bottomLeft" 
                        trigger="click"
                        arrow
                    >
                        <Button
                            size="large"
                            type="primary"
                            loading={isLoading}
                            icon={<PlusOutlined />}
                        >
                            Santri
                        </Button>
                    </Dropdown>
                </div>
                <div className="lembaga-right-section">
                    <ComponentFilterSearch 
                        dataToSearch={santriData}
                        setTableData={setTableData}
                        placeholder={"Pencarian"}
                    />
                </div>
            </div>
            <Divider orientation="right" style={{ textTransform: "capitalize" }} >{unit_nama}</Divider>
            <div className="table-container">
                <ComponentTable 
                    tableData={tableData}
                    setIsSuccessHitAPI={setIsSuccessHitAPI}
                />
            </div>
        </div>
    );
};