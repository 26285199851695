import axios from "axios";
import { removeEmptyObjectKeys, openNotification } from "../_helpers";

export const getAllKomponen = async () => {
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/finance/komponen/find`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // setDataPeriode(response.data.data);
        localStorage.setItem("komponen", JSON.stringify(response.data.data));
        // console.log(response.data.data);
    } catch (error) {
        // console.log(error.response);
    }
};

export const insertKomponen = async (payload, handleCloseModal, setIsLoading, setIsSuccessHitAPI) => {
    setIsLoading(true);
    setIsSuccessHitAPI(false);
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));
    // console.log(payload);

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/finance/komponen/insert`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllKomponen();
        setIsLoading(false);
        setIsSuccessHitAPI(true);
        handleCloseModal();
        openNotification("success", "Sukses", "Data Komponen berhasil ditambah!");
        // console.log(response.data.data);
    } catch (error) {
        setIsLoading(false);
        setIsSuccessHitAPI(false);
        openNotification("error", "Gagal", "Terjadi kesalahan saat menambahkan data Komponen, coba lagi!");
        // console.log(error.response);
    }
};

export const updateKomponen = async (payload, handleCloseModal, setIsLoading, setIsSuccessHitAPI) => {
    setIsLoading(true);
    setIsSuccessHitAPI(false);
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));

    const finalPayload = {
        nama_komponen: payload.nama_komponen,
        unit_id: payload.unit_id,
    };
    console.log(finalPayload);

    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_PATH}/finance/komponen/update?komponenId=${payload.komponen_id}`, finalPayload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllKomponen();
        setIsLoading(false);
        setIsSuccessHitAPI(true);
        handleCloseModal();
        openNotification("success", "Sukses", "Data Komponen berhasil diubah!");
        // console.log(response.data.data);
    } catch (error) {
        setIsLoading(false);
        setIsSuccessHitAPI(false);
        openNotification("error", "Gagal", "Terjadi kesalahan saat mengubah data Komponen, coba lagi!");
        // console.log(error.response);
    }
};

export const deleteKomponen = async (komponenID, setIsLoading, setIsSuccessHitAPI) => {
    setIsLoading(true);
    setIsSuccessHitAPI(false);
    const { token } = JSON.parse(localStorage.getItem("userCredentials"));
    // console.log(komponenID);

    try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_PATH}/finance/komponen/delete?komponenId=${komponenID}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getAllKomponen();
        setIsLoading(false);
        setIsSuccessHitAPI(true);
        openNotification("success", "Sukses", "Data Komponen berhasil dihapus!");
        // console.log(response.data.data);
    } catch (error) {
        setIsLoading(false);
        setIsSuccessHitAPI(false);
        openNotification("error", "Gagal", "Terjadi kesalahan saat menghapus data Komponen, coba lagi!");
        // console.log(error.response);
    }
};