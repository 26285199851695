import React, { useMemo, useState } from "react";
import { 
    Button, 
    Modal,
    Space,
    Typography,
    Table,
} from 'antd';

const { Text } = Typography;

export const ComponentModalDetail = ({dataRow, isModalDetailOpen, setModalDetailOpen}) => {
    const tableData = [dataRow];

    const tableColumns = [
        {
            title: "NIS",
            dataIndex: "nis",
        },
        {
            title: "Kelas",
            dataIndex: "kelas",
        },
        {
            title: "Nama Santri",
            dataIndex: "nama",
        },
        {
            title: "Jenis Kelamin",
            dataIndex: "jenis_kelamin",
            render: ((_, {jenis_kelamin}) => {
                switch (jenis_kelamin) {
                    case "L":
                        return (
                            <Text>Pria</Text>
                        );
                    case "P":
                        return (
                            <Text>Wanita</Text>
                        );
                    default:
                        return null;
                }
            })
        }
    ];

    return (
        <Modal 
            title="Detail Santri" 
            open={isModalDetailOpen} 
            onCancel={() => {
                setModalDetailOpen(!isModalDetailOpen);
            }}
            footer={[
                <Button 
                    key="ok"
                    type="primary" 
                    onClick={() => {
                        setModalDetailOpen(!isModalDetailOpen);
                    }}
                >
                    OK
                </Button>
            ]}
        >
            <Space direction="vertical" style={{ display: "flex" }}>
                <div style={{ display: "flex", marginBottom: "15px" }}>
                    <Text style={{ textTransform: "capitalize", fontSize: "15px", fontWeight: "bold" }} >{dataRow?.lembaga}</Text>
                </div>
                
                <Table 
                    rowKey={(data) => data.nis !== null ? data.nis : Math.floor(Math.random() * 100000000).toString()}
                    pagination={false}
                    style={{ width: "100%" }} 
                    columns={tableColumns} 
                    dataSource={tableData}
                />

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text style={{ color: "silver" }}>Email</Text>
                    <Text style={{ paddingLeft: "20px", fontWeight: "bolder" }}>{dataRow?.email}</Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text style={{ color: "silver" }}>Nomor Handphone</Text>
                    <Text style={{ paddingLeft: "20px", fontWeight: "bolder" }}>{dataRow?.nomor_hp}</Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text style={{ color: "silver" }}>Nama Orang Tua / Wali</Text>
                    <Text style={{ paddingLeft: "20px", fontWeight: "bolder" }}>{dataRow?.nama_orang_tua_wali}</Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text style={{ color: "silver" }}>Nomor Handphone Orang Tua / Wali</Text>
                    <Text style={{ paddingLeft: "20px", fontWeight: "bolder" }}>{dataRow?.nomor_hp_orang_tua_wali}</Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text style={{ color: "silver" }}>Alamat</Text>
                    <Text style={{ paddingLeft: "20px", fontWeight: "bolder" }}>{dataRow?.alamat}</Text>
                </div>
            </Space>
        </Modal>
    );
};